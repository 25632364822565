import RoundedButton from '@/components/common/RoundedButton';

const WarningPhoneNumberToast = () => (
  <>
    <div className="space-y-16">
      <div className="space-y-4 text-body-sm text-npl-text-icon-on-light-surface-primary">
        WhatsApp number is taken and could not be saved. Speak to your
        community host or contact us.
      </div>

      <div className="flex space-x-12">
        <RoundedButton
          displayType="outline"
          link="mailto:hello@nas.io"
          isOpenedNewTab={true}
          size="sm">
          Contact us
        </RoundedButton>
      </div>
    </div>
  </>
);

export default WarningPhoneNumberToast;
