import {
  EVENT_ACCESS_PAID,
  EVENT_TYPE_IN_PERSON,
  EVENT_TYPE_LIVE
} from '@/pages/portal/events/constants';
import {
  LOGIN_FORM,
  SIGNUP_FORM
} from '@/components/common/CheckoutPartials/PreCheckoutStep/PreCheckoutStep';
import { format, sub } from 'date-fns';
import {
  getAuthenticatedEventBySlug,
  getCommunityPricingByDiscountCodeService,
  getCommunityPricingServiceV2,
  getEventBySlug
} from '@/services/communitiesService';
import {
  getEventCheckoutLink,
  resolveBaseUrl,
  resolveEventSlugUrl,
  resolveEventSlugUrlWithConfig,
  setLastVisitedEvent
} from '@/pages/portal/utils/events';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useUserCommunities, {
  getUserRoleInCommunity
} from '@/hooks/useUserCommunities';

import AdminAuth from '@/modules/AdminAuth';
import Auth from '@/modules/Auth';
import BlurredBackground from '@/features/EventPublicPage/components/BlurredBackground/BlurredBackground';
import BottomSticky from '@/features/EventPublicPage/components/BottomSticky';
import {
  CARD_SHADOW,
  EVENT_LOCATION_TEXT
} from '@/features/EventPublicPage/constants';
import CommunityDetail from '@/features/EventPublicPage/components/CommunityDetail/CommunityDetail';
import EmailForm from '@/components/common/Form/EmailForm';
import { EventConfirmationModal } from './components/EventConfirmationModal/EventConfirmationModal';
import EventDetail from '@/features/EventPublicPage/components/EventDetail/EventDetail';
import EventFooter from '@/features/EventPublicPage/components/EventFooter/EventFooter';
import FreeCommunityRedirectModal from '../CommunityProductPage/components/FreeCommunityRedirectModal';
import IconByName from '@/components/common/IconsV2/IconByName';
import ImageBanner from './components/ImageBanner';
import JoinCommunityFeature from '../JoinCommunityFeature';
import { Player } from '@lottiefiles/react-lottie-player';
import Modal from '@/components/common/Modal';
import Navbar from '../community/components/Navbar';
import PageMetaTags from '@/components/common/PageMetaTags';
import PreCheckoutStep from '@/components/common/CheckoutPartials/PreCheckoutStep';
import { SIGN_UP_ID_SESSION_KEY } from '@/utility/checkoutConstants';
import ShareModal from '@/components/common/ShareModal/ShareModal';
import TicketCard from './components/TicketCard';
import config from '@/utility/config';
import { getPortalUrl } from '../community/utils';
import { getRedirectLinkOnPaymentSuccess } from '@/services/signUpService';
import { retryablePromise } from '@/pages/portal/utils/promise';
import { rsvpEvent } from '@/services/community/communitiesManagementEventsService';
import style from './EventPublicPage.module.scss';
import { trackGAEvent } from '@/utility/analytics';
import { useAuthContext } from '@/contexts/AuthContext';
import { useRouter } from 'next/router';
import useStartCheckout from '@/hooks/checkout/useStartCheckout';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import WhatsappSignUpPopup from '../WhatsappSignUpPopup';
import { SIGNUP_ORIGIN } from '../WhatsappSignUpPopup/constants';
import { formatNumber } from '@/utility/helpers';
import { getDiscountCodeFromUrlParams } from '@/utility/routesHelper';
import { formatPriceCents } from '@/pages/portal/utils/number';
import { DISCOUNT_ENTITY_TYPE } from '@/hooks/useDiscount';
import { formatNewPricingApiToOldFormat } from '@/utility/communityHelpers';

// Non logged in new user + logged in user that is not in that community (has no access)
// Event Part
export const EVENT_STATUS_TYPE = {
  PAST: 'past',
  UPCOMING: 'upcoming',
  STARTING_SOON: 'starting-soon',
  HAPPENING_NOW: 'happening-now'
};

export const USER_FLOW_TYPE = {
  LOGGED_OUT: 'logged-out',
  LOGGED_IN_RSVP: 'logged-in-rsvp',
  LOGGED_IN_NOT_RSVP: 'logged-in-not-rsvp'
};

export const EVENT_ACTION_TYPE = 'event';

const getEventStatusType = ({ current, start, end }) => {
  const currentValue = new Date(current).getTime();
  const startValueOneHourEarly = sub(new Date(start), {
    hours: 1
  }).getTime();
  const startValue = new Date(start).getTime();
  const endValue = new Date(end).getTime();

  //1. Past: if end date > current date
  if (endValue < currentValue) return EVENT_STATUS_TYPE.PAST;

  //2. Soon: if event start date - 1hr < current date
  if (startValueOneHourEarly < currentValue && currentValue < startValue)
    return EVENT_STATUS_TYPE.STARTING_SOON;

  //3. Now: if end date > current date > start date
  if (endValue > currentValue && currentValue > startValue)
    return EVENT_STATUS_TYPE.HAPPENING_NOW;

  //4. Upcoming: if start date > current date
  if (startValue > currentValue) return EVENT_STATUS_TYPE.UPCOMING;
};

const EventPublicPage = (props) => {
  const pageData = props.pageInfo.templateData;

  const whatsappSignUpPopupRef = useRef();

  const [eventInfo, setEventInfo] = useState(pageData.eventInfo);
  const [communityInfo, setCommunityInfo] = useState(
    pageData.communityInfo
  );
  const eventSlug = pageData.eventSlug;
  const communitySlug = pageData.communitySlug;
  const eventObjectId = eventInfo?._id;
  const communityObjectId = communityInfo?._id;

  const [authEventData, setAuthEventData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  // const [isCommunityMember, setIsCommunityMember] = useState(false);
  const [userFlowType, setUserFlowType] = useState('');
  const [clientCurrentDate, setClientCurrentDate] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] =
    useState(false);
  const [clientTimezone, setClientTimezone] = useState('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [joinCommunityModalOpened, setJoinCommunityModalOpened] =
    useState(false);
  const [isUserEnrolled, setIsUserEnrolled] = useState(false);
  const [showSuccessMessageOnToast, setShowSuccessMessageOnToast] =
    useState(false);
  const [messageOnToast, setMessageOnToast] = useState(null);
  const [checkoutForm, setCheckoutForm] = useState(SIGNUP_FORM);

  const {
    isWaitlist = false,
    request_approval: requestApproval = false,
    payment_methods
  } = communityInfo;
  const nftOnly = payment_methods?.[0]?.value === 'web3';
  const isFreeCommunity = communityInfo?.isFreeCommunity;

  const { isWhatsappExperienceCommunity } = communityInfo ?? {};
  const [isWhatsappSignUpModalOpened, setIsWhatsappSignUpModalOpened] =
    useState(false);
  const [discountedCodeData, setDiscountedCodeData] = useState();
  const [selectedPricingOption, setSelectedPricingOption] = useState(null);
  const [pricingData, setPricingData] = useState(null);

  const needsApplicationReview = requestApproval;

  const showPricing = useMemo(() => {
    return pricingData || isFreeCommunity || nftOnly || isWaitlist;
  }, [pricingData, isFreeCommunity, nftOnly, isWaitlist]);

  const firstPricingData = useMemo(() => {
    if (!pricingData || !pricingData.length) {
      return null;
    }
    return pricingData[0];
  }, [pricingData]);

  const { pricePerMonth } = useMemo(() => {
    const currency = selectedPricingOption?.currency?.toUpperCase() || '';
    const interval =
      selectedPricingOption?.recurring?.interval === 'year'
        ? 12
        : selectedPricingOption?.recurring?.interval_count;
    return {
      currency,
      pricePerMonth: selectedPricingOption?.unit_amount / interval / 100
    };
  }, [selectedPricingOption]);

  const eventCurrency =
    eventInfo?.priceDetails?.localCurrency ??
    eventInfo?.priceDetails?.currency ??
    'USD';

  const eventPrice =
    eventInfo?.access === EVENT_ACCESS_PAID
      ? eventInfo?.priceDetails?.localAmount ??
        eventInfo?.priceDetails?.amount
      : 0;

  const discountedEventPrice = useMemo(() => {
    if (discountedCodeData) {
      const { type, value } = discountedCodeData;
      if (type === 'percentage') {
        return parseFloat(
          Number(eventPrice * (1 - value / 100)).toFixed(2)
        );
      }
    }

    return null;
  }, [discountedCodeData, eventPrice]);

  const formattedEventPriceText = (() => {
    const formattedEventPrice = formatPriceCents(eventPrice);
    const formattedDiscountedEventPrice = formatPriceCents(
      discountedEventPrice
    );

    if (eventInfo?.access === 'free') return '';

    if (discountedEventPrice)
      return (
        <>
          {eventCurrency} {formattedDiscountedEventPrice}
          <span className="ml-4 text-npl-text-icon-on-light-surface-tertiary line-through">
            {formattedEventPrice}
          </span>
        </>
      );

    return (
      <>
        {eventCurrency} {formattedEventPrice}
      </>
    );
  })();

  const formattedPricePerMonth = useMemo(() => {
    return formatNumber(pricePerMonth);
  }, [pricePerMonth]);

  const eventStatusType = getEventStatusType({
    start: eventInfo.startTime,
    end: eventInfo.endTime,
    current: clientCurrentDate
  });

  const router = useRouter();
  const { loginWithToken, isLoggedIn, user } = useAuthContext();

  const communityCode = communityInfo?.code;
  const discountCodeFromQueryParams = getDiscountCodeFromUrlParams(router);

  const { loading, userCommunities } = useUserCommunities();

  const { isCommunityMember, isCommunityAdmin } = useMemo(() => {
    const { isCommunityAdmin, isCommunityMember } = getUserRoleInCommunity(
      communityCode,
      userCommunities
    );
    return { isCommunityMember, isCommunityAdmin };
  }, [communityCode, userCommunities]);

  const [isTimeLoading, setIsTimeLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { data: eventData, error: eventError } = await getEventBySlug(
        `${communitySlug}/${eventSlug}`
      );
      if (eventError) {
        return;
      }
      setEventInfo(eventData.eventInfo);
      setCommunityInfo(eventData.communityInfo);
      setIsTimeLoading(false);
    })();
  }, [communitySlug, eventSlug]);

  // const isUserAdminOfCommunity = isLoggedIn && isAdmin && role === 'admin';
  // const isUserAdminOfCommunity = isLoggedIn && role === roles.admin;
  const isUserAdminOfCommunity = isLoggedIn && isCommunityAdmin;

  const { isGtEqMd } = useWindowWidthContext();

  const containerBackgroundTransparent = true;
  // Do not redirect to community checkout flow if community is free + without application.
  const shouldPreventCheckoutRedirect =
    isFreeCommunity && !communityInfo?.request_approval;

  const isPaidEvent =
    eventInfo?.access === EVENT_ACCESS_PAID &&
    eventInfo?.priceDetails?.amount > 0;
  // const isPaidEvent =
  //   eventInfo?.access === EVENT_ACCESS_PAID && eventInfo?.amount > 0;

  const isEventSoldOut = !!eventInfo?.isSoldOut;

  const isDemoEvent = !!eventInfo?.isDemo;

  const onRsvpFreeEvent = async ({ setUserFlow = false, bookingId }) => {
    const communityHasApplication = !!communityInfo?.request_approval;

    // if (!communityHasApplication) {
    //   if (isPaidEvent) {
    //     // TODO: redirect to event checkout page
    //     // This function is called after community checkout, so once the user has access to the community
    //     // we want to ask them to pay for the event
    //   }
    // }

    // Do nothing here is the event is paid
    if (isPaidEvent) return;

    // Not a community member + community has checkout redirect + Event is free
    if (
      !isCommunityMember &&
      (!isFreeCommunity || communityHasApplication)
    ) {
      // Do nothing for Paid and Token Gated communities or has approval
      return;
    }

    // Rsvp the user to the free event.
    try {
      let accessToken = Auth.getUserToken();
      let refreshToken;

      if (!accessToken) {
        // Cache might not be ready, so we retry until it's ready up to 5 times
        // with an increasing delay between request
        const { data, error: redirectError } = await retryablePromise(() =>
          getRedirectLinkOnPaymentSuccess({
            bookingId:
              bookingId ?? sessionStorage.getItem(SIGN_UP_ID_SESSION_KEY),
            isCommunity: 1
          })
        );

        if (redirectError) throw new Error(redirectError);

        accessToken = data?.authToken;
        refreshToken = data?.refreshToken;
      }

      const { error } = await rsvpEvent(
        clientTimezone,
        eventInfo._id,
        accessToken
      );

      if (error) throw new Error(error);

      if (setUserFlow) setUserFlowType(USER_FLOW_TYPE.LOGGED_IN_RSVP);

      if (!isLoggedIn) loginWithToken(accessToken, refreshToken);
    } catch (e) {
      // RSVP will move to backend soon, consume and ignore any error
      // showToast({ text: e.message, type: 'error' });
    }
    await getPrivateEventData(true);
  };

  const onLoginClick = async () => {
    setCheckoutForm(LOGIN_FORM);
    await onCommunityCheckoutClick({
      communityCode: communityInfo?.code,
      priceId: selectedPricingOption?.id
    });
    // await getPrivateEventData();
  };

  const postCommunitySignupProcess = async (params) => {
    if (!params?.bookingId) return;
    const { bookingId } = params;
    // Do nothing post community signup if the event is paid
    if (isPaidEvent) {
      const hasApplication = !!communityInfo?.request_approval;
      if (
        !hasApplication &&
        [EVENT_STATUS_TYPE.HAPPENING_NOW, EVENT_STATUS_TYPE.PAST].includes(
          eventStatusType
        )
      ) {
        try {
          let accessToken =
            Auth.getUserToken() || AdminAuth.getUserToken();
          // let refreshToken =
          //   Auth.getRefreshToken() || AdminAuth.getRefreshToken();

          if (!accessToken) {
            // Cache might not be ready, so we retry until it's ready up to 5 times
            // with an increasing delay between request
            const { data, error: redirectError } = await retryablePromise(
              () =>
                getRedirectLinkOnPaymentSuccess({
                  bookingId:
                    bookingId ??
                    sessionStorage.getItem(SIGN_UP_ID_SESSION_KEY),
                  isCommunity: 1
                })
            );

            if (redirectError) throw new Error(redirectError);

            accessToken = data?.authToken;
            // refreshToken = data?.refreshToken;

            // let route =
            //   `${config.memberPortalPath}?token=${accessToken}&refreshToken=${refreshToken}` ||
            //   `https://member.nas.io/communities?`;
            // if (isUserAlreadyEnrolled) {
            //   route += `&activeCommunityId=${communityInfo?._id}`;
            // }
            // window.location.href = route;

            // const route = `${config.memberPortalPath}/communities/${communityId}/home`
            const communityId = communityInfo?._id;

            const route = communityId
              ? `${config.memberPortalPath}/communities/${communityId}/home`
              : `${config.memberPortalPath}/communities`;

            return router.push(route);
          }

          // if (!isLoggedIn) loginWithToken(accessToken, refreshToken);
        } catch (e) {
          // Consume any error
        }
        trackGAEvent('public_event_page_signup', {
          eventId: eventInfo?._id,
          email: user?.email
        });
        await getPrivateEventData(true);
      }
      if (
        !hasApplication &&
        [
          EVENT_STATUS_TYPE.UPCOMING,
          EVENT_STATUS_TYPE.STARTING_SOON
        ].includes(eventStatusType)
      ) {
        try {
          let accessToken = Auth.getUserToken();
          let refreshToken;
          let resetToken;

          if (!accessToken) {
            // Cache might not be ready, so we retry until it's ready up to 5 times
            // with an increasing delay between request
            const { data, error: redirectError } = await retryablePromise(
              () =>
                getRedirectLinkOnPaymentSuccess({
                  bookingId:
                    bookingId ??
                    sessionStorage.getItem(SIGN_UP_ID_SESSION_KEY),
                  isCommunity: 1
                })
            );

            if (redirectError) throw new Error(redirectError);

            accessToken = data?.authToken;
            refreshToken = data?.refreshToken;
            resetToken = data?.resetToken;
          }

          if (!isLoggedIn) loginWithToken(accessToken, refreshToken);

          const extraQueryParams = {
            token: accessToken,
            resetToken: resetToken,
            ...(discountCodeFromQueryParams && {
              discountCode: discountCodeFromQueryParams
            })
          };

          if (discountCodeFromQueryParams)
            extraQueryParams.discountCode = discountCodeFromQueryParams;

          window.location.href = getEventCheckoutLink(
            eventObjectId,
            communityObjectId,
            extraQueryParams
          );
        } catch (e) {
          // Consume any error
        }
        trackGAEvent('public_event_page_signup', {
          eventId: eventInfo?._id,
          email: user?.email
        });
        await getPrivateEventData(true);
      }
    } else {
      onRsvpFreeEvent({ bookingId });
    }
  };

  // Pre-checkout hooks
  const {
    // modal state
    showPreCheckoutModal,
    setShowPreCheckoutModal,
    // login data
    isCheckingOutAsLoggedInUser,
    selectCheckoutAsLoggedInUser,
    deselectCheckoutAsLoggedInUser,
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,
    loginInputErrors,
    // signup data
    handleCheckoutWithEmail,
    onSignupEmailChange,
    onDialCodeIdChange,
    onPhoneNumberChange,
    signupWithEmailErr,
    signUpErrors,
    // setSignUpErrors,
    signupEmail,
    dialCodeId,
    phoneNumber,
    loadingText,
    isSigningUpWithEmail,
    isSubmittingSignup,
    isUserAlreadyEnrolled,
    setIsUserAlreadyEnrolled,

    // error prompt
    promptError,
    resetPromptError,

    onCommunityCheckoutClick,

    // free community
    shouldStartOutsideRedirectFlow,
    setShouldStartOutsideRedirectFlow
  } = useStartCheckout({
    // groupCode: communityInfo?.groupCode ?? '',
    communityCode: communityInfo?.code,
    slug: communityInfo?.link,
    isCommunity: true,
    isFreeWorkshop: false,
    isFreeCommunity,
    isCommunityRequireApproval: communityInfo?.request_approval,
    shouldPreventCheckoutRedirect,
    postSignupCallback: postCommunitySignupProcess,
    onUserAlreadyExists: onLoginClick,
    actionType: EVENT_ACTION_TYPE,
    sourceInfo: {
      type: 'event',
      origin: eventObjectId
    },
    entityDiscountCodeFromQueryParams: discountCodeFromQueryParams
  });

  const onSignUp = async () => {
    // if the community is a whatsapp beta community, open the whatsapp signup popup instead of the community sign up popup
    if (isWhatsappExperienceCommunity) {
      setIsWhatsappSignUpModalOpened(true);
      return;
    }

    setCheckoutForm(SIGNUP_FORM);
    await onCommunityCheckoutClick({
      communityCode: communityInfo?.code,
      priceId: selectedPricingOption?.id
    });
    await getPrivateEventData();
  };

  const shareUrl = resolveEventSlugUrl(communityInfo.link, eventInfo.slug);
  const metaUrl = resolveEventSlugUrlWithConfig(
    communityInfo.link,
    eventInfo.slug
  );

  const calendarDetails = {
    title: `${
      communityInfo?.title
        ? `[${communityInfo?.title} Event]`
        : '[Nas.io Event]'
    } ${eventInfo?.title}`,
    location: eventInfo?.liveLink ?? shareUrl,
    description: eventInfo?.description,
    startTime: eventInfo?.startTime,
    endTime: eventInfo?.endTime
  };

  const getEventProminentUrlProps = () => {
    const isRegistered = authEventData?.registered;

    if (eventInfo.type === EVENT_TYPE_LIVE) {
      const locationText = isRegistered
        ? authEventData?.eventInfo?.liveLink ?? EVENT_LOCATION_TEXT.LIVE
        : EVENT_LOCATION_TEXT.LIVE;
      return {
        type: eventInfo.type,
        text: locationText.trim(),
        showCopyIcon: locationText !== EVENT_LOCATION_TEXT.LIVE
      };
    }

    if (eventInfo.type === EVENT_TYPE_IN_PERSON) {
      const locationText = isRegistered
        ? authEventData?.eventInfo?.inPersonLocation ??
          EVENT_LOCATION_TEXT.IN_PERSON
        : EVENT_LOCATION_TEXT.IN_PERSON;
      return {
        type: eventInfo.type,
        text: locationText.trim(),
        showCopyIcon: locationText !== EVENT_LOCATION_TEXT.IN_PERSON
      };
    }

    return {};
  };

  const getEventTimingProps = () => {
    const startDate = new Date(eventInfo.startTime);
    const endDate = new Date(eventInfo.endTime);

    return {
      weekday: format(startDate, 'EEEE'),
      day: format(startDate, 'dd'),
      month: format(startDate, 'MMMM'),
      startTime: format(startDate, 'hh:mma'),
      endTime: format(endDate, 'hh:mma')
    };
  };

  const getBannerHeader = () => {
    if (isDemoEvent) {
      return {
        title: 'This is a demo event'
      };
    }

    if (eventStatusType === EVENT_STATUS_TYPE.PAST)
      return {
        title: 'This event has ended'
      };

    if (
      eventStatusType === EVENT_STATUS_TYPE.UPCOMING &&
      userFlowType === USER_FLOW_TYPE.LOGGED_IN_RSVP
    )
      return {
        icon: <IconByName name="check" fill="#fbfcff" />,
        title: 'You’re going for this event!'
      };

    if (eventStatusType === EVENT_STATUS_TYPE.STARTING_SOON)
      return {
        title: 'This event is starting soon!'
      };

    if (eventStatusType === EVENT_STATUS_TYPE.HAPPENING_NOW)
      return {
        title: 'This event is happening now!',
        icon: (
          <Player
            className="w-20 h-20 mix-blend-screen"
            src="/animation/happening-now-lottie.json"
            loop
            autoplay
          />
        )
      };

    return {};
  };

  const redirectToEventLink = () =>
    window.open(authEventData?.eventInfo?.liveLink, '_blank').focus();

  const redirectToLPWithTokens = () => {
    const accessToken = Auth.getUserToken();
    const refreshToken = Auth.getRefreshToken();
    router.push(
      `${process.env.NEXT_PUBLIC_LEARNER_PORTAL_PATH}/communities/${communityInfo?._id}/events?token=${accessToken}&refreshToken=${refreshToken}&activeCommunityId=${communityInfo?._id}`
    );
  };

  const openEditEventPage = () => {
    router.push(resolveBaseUrl(`/portal/events/edit/${eventInfo?._id}`));
  };

  const handleEditEvents = () => {
    let path = `${getPortalUrl('events', communityInfo?._id)}`;
    router.push(path);
  };

  // This method is only for paid event
  const onGetTicket = () => {
    if (isCommunityMember) {
      const extraQueryParams = {
        token: Auth.getUserToken()
      };

      if (discountCodeFromQueryParams)
        extraQueryParams.discountCode = discountCodeFromQueryParams;

      window.location.href = getEventCheckoutLink(
        eventObjectId,
        communityObjectId,
        extraQueryParams
      );
      return;
    }

    // is user is not a member of communitys
    onSignUp();
  };

  const goToPortalCta = {
    text: 'Go to Portal',
    onClick: redirectToLPWithTokens,
    displayType: 'outline'
  };

  const getTicketCta = {
    text: 'Get Ticket',
    displayType: 'primary',
    disabled: isDemoEvent,
    onClick: onGetTicket
  };

  const soldOutCta = {
    text: 'Sold Out',
    disabled: isEventSoldOut,
    onClick: () => {}
  };

  const registerCta = {
    text: 'Register',
    displayType: 'primary',
    disabled: isDemoEvent,
    onClick: onSignUp
  };

  const joinNowCta = {
    text: 'Join now',
    displayType: 'primary',
    disabled: isDemoEvent,
    onClick: redirectToEventLink
  };

  const goToCmPortalCta = {
    text: 'Go to Portal',
    onClick: () => {
      router.push(resolveBaseUrl(`/portal`));
    },
    displayType: 'outline'
  };

  const getTicketCardProps = () => {
    const isInPersonEvent = eventInfo.type === EVENT_TYPE_IN_PERSON;
    const isPaidEvent =
      eventInfo?.access === EVENT_ACCESS_PAID &&
      eventInfo?.priceDetails?.amount > 0;

    if (isUserAdminOfCommunity) {
      if (
        eventStatusType === EVENT_STATUS_TYPE.PAST ||
        eventStatusType === EVENT_STATUS_TYPE.HAPPENING_NOW
      ) {
        return {
          showLoginText: false,
          primaryCta: goToCmPortalCta
        };
      }
      return {
        showLoginText: false,
        primaryCta: {
          text: 'Edit event',
          displayType: 'outline',
          disabled: isDemoEvent,
          onClick: () => openEditEventPage()
        }
      };
    }

    switch (eventStatusType) {
      case EVENT_STATUS_TYPE.PAST: {
        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_NOT_RSVP &&
          isCommunityMember
        )
          return {
            eyebrowText: 'We hope you enjoyed this event!',
            showLoginText: false,
            primaryCta: goToPortalCta
          };

        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_RSVP &&
          isCommunityMember
        )
          return {
            eyebrowText: 'We hope you enjoyed this event!',
            showLoginText: false,
            primaryCta: goToPortalCta
          };

        return {
          eyebrowText: 'Get access to all future events in this community',
          showLoginText: !isLoggedIn,
          primaryCta: {
            text: 'Register',
            displayType: 'primary',
            disabled: isDemoEvent,
            onClick: onSignUp
          }
        };
      }
      case EVENT_STATUS_TYPE.UPCOMING: {
        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_NOT_RSVP &&
          isCommunityMember
        ) {
          if (isEventSoldOut) {
            return {
              showLoginText: false,
              primaryCta: soldOutCta
            };
          }

          if (isPaidEvent) {
            return {
              showLoginText: false,
              primaryCta: getTicketCta
            };
          }

          return {
            eyebrowText: 'Let the host know you’re going',
            showLoginText: false,
            primaryCta: {
              text: 'I’m going',
              displayType: 'primary',
              disabled: isDemoEvent,
              onClick: () => onRsvpFreeEvent({ userFlowType: true })
            }
          };
        }

        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_RSVP &&
          isCommunityMember
        ) {
          return {
            showLoginText: false,
            primaryCta: {
              isCalendarCta: true,
              text: 'Add to calendar',
              disabled: isDemoEvent,
              displayType: 'outline'
            },
            secondaryCta: goToPortalCta
          };
        }

        if (isEventSoldOut) {
          return {
            showLoginText: false,
            primaryCta: soldOutCta
          };
        }

        if (isPaidEvent) {
          return {
            showLoginText: !isLoggedIn,
            primaryCta: getTicketCta
          };
        }

        return {
          showLoginText: !isLoggedIn,
          primaryCta: registerCta
        };
      }
      case EVENT_STATUS_TYPE.STARTING_SOON: {
        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_NOT_RSVP &&
          isCommunityMember
        ) {
          if (isEventSoldOut) {
            return {
              showLoginText: false,
              primaryCta: soldOutCta
            };
          }

          if (isPaidEvent) {
            return {
              showLoginText: false,
              primaryCta: getTicketCta
            };
          }

          return {
            showLoginText: false,
            primaryCta: isInPersonEvent
              ? null
              : authEventData?.eventInfo?.liveLink
              ? joinNowCta
              : null,
            secondaryCta: goToPortalCta
          };
        }

        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_RSVP &&
          isCommunityMember
        ) {
          return {
            showLoginText: false,
            primaryCta: isInPersonEvent
              ? null
              : authEventData?.eventInfo?.liveLink
              ? joinNowCta
              : null,
            secondaryCta: goToPortalCta
          };
        }

        if (isEventSoldOut) {
          return {
            showLoginText: false,
            primaryCta: soldOutCta
          };
        }

        if (isPaidEvent) {
          return {
            showLoginText: false,
            primaryCta: getTicketCta
          };
        }

        return {
          showLoginText: !isLoggedIn,
          primaryCta: registerCta
        };
      }
      case EVENT_STATUS_TYPE.HAPPENING_NOW: {
        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_NOT_RSVP &&
          isCommunityMember
        ) {
          if (isEventSoldOut) {
            return {
              showLoginText: false,
              primaryCta: soldOutCta
            };
          }

          if (isPaidEvent) {
            return {
              showLoginText: !isLoggedIn,
              primaryCta: null,
              eyebrowText:
                'This event has started, but you haven’t purchased a ticket yet.',
              secondaryCta: goToPortalCta
            };
          }

          return {
            showLoginText: false,
            primaryCta: isInPersonEvent
              ? null
              : authEventData?.eventInfo?.liveLink
              ? joinNowCta
              : null,
            secondaryCta: goToPortalCta
          };
        }

        if (
          userFlowType === USER_FLOW_TYPE.LOGGED_IN_RSVP &&
          isCommunityMember
        ) {
          return {
            showLoginText: false,
            primaryCta: isInPersonEvent
              ? null
              : authEventData?.eventInfo?.liveLink
              ? joinNowCta
              : null,
            secondaryCta: goToPortalCta
          };
        }

        if (isEventSoldOut) {
          return {
            showLoginText: false,
            primaryCta: soldOutCta
          };
        }

        if (isPaidEvent) {
          return {
            showLoginText: !isLoggedIn,
            eyebrowText:
              'Get access to all future events in this community',
            primaryCta: registerCta
          };
        }

        return {
          showLoginText: !isLoggedIn,
          primaryCta: registerCta
        };
      }
    }
  };

  const getPrivateEventData = useCallback(
    async (newRegistration = false) => {
      try {
        setIsLoading(true);
        const { data, error } = await getAuthenticatedEventBySlug(
          `/${communitySlug}/${eventSlug}`
        );

        if (error) throw new Error(error);

        setAuthEventData(data);

        // const { data: communityData, error: communityError } =
        //   await getCommunitiesList({
        //     activeCommunityId: communityInfo._id
        //   });

        // if (communityError) throw new Error(communityError);

        // const communityList = communityData?.communities ?? [];

        // const isEnrolledInTheCommunity = communityList?.find(
        //   (community) => community._id === communityInfo._id
        // );

        // if (isEnrolledInTheCommunity) setIsCommunityMember(true);

        if (data.registered) {
          setUserFlowType(USER_FLOW_TYPE.LOGGED_IN_RSVP);
          if (newRegistration && !isWhatsappExperienceCommunity)
            setShowConfirmationModal(true);
        } else setUserFlowType(USER_FLOW_TYPE.LOGGED_IN_NOT_RSVP);
      } catch (e) {
        setUserFlowType(USER_FLOW_TYPE.LOGGED_OUT);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [communitySlug, eventSlug, isWhatsappExperienceCommunity]
    // [communityInfo._id, communitySlug, eventSlug]
  );

  const headingStylingOnModal = containerBackgroundTransparent
    ? 'mb-8 lg:text-center md:text-left  font-semibold font-poppins text-neutral-99 lg:text-28 text-24 '
    : 'mb-8 font-bold font-poppins text-dark-33 text-16 xl:text-24';

  const paragraphStylingOnModal = containerBackgroundTransparent
    ? 'mb-24 lg:text-center  md:text-left font-normal font-poppins text-white-default text-16 '
    : 'mb-24 font-poppins text-12 text-dark-2c xl:text-16';

  const closeJoinCommunityModal = useCallback(() => {
    setJoinCommunityModalOpened(false);
  }, []);

  const emailFormProps = useCallback(() => {
    return {
      containerBackgroundTransparent,
      closeJoinCommunityModal: closeJoinCommunityModal,
      closeModalText: 'Close',
      ctaTextLabel: 'Join Waitlist'
    };
  }, [closeJoinCommunityModal, containerBackgroundTransparent]);

  const renderJoinWaitlistForm = useCallback((emailProps) => {
    return (
      <div className={style['emailWrapper']}>
        <EmailForm {...emailProps} {...emailFormProps()} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderJoinWaitlistFormSubSection = useCallback(
    (emailProps) => {
      return (
        <>
          <h2 className={headingStylingOnModal}>{communityInfo.title}</h2>
          <p className={paragraphStylingOnModal}>
            We&apos;ll let you know as soon as this community starts!
          </p>
          {renderJoinWaitlistForm(emailProps)}
        </>
      );
    },
    [
      headingStylingOnModal,
      paragraphStylingOnModal,
      renderJoinWaitlistForm,
      communityInfo.title
    ]
  );

  const setUserEnrolled = useCallback(() => {
    setIsUserEnrolled(true);
  }, []);

  const renderCustomJoinWaitlistInput = useCallback(
    (emailProps) => (
      <Modal
        showCloseIcon={false}
        containerBackgroundTransparent={containerBackgroundTransparent}
        size="md"
        open={true}
        onClose={closeJoinCommunityModal}>
        {renderJoinWaitlistFormSubSection(emailProps)}
      </Modal>
    ),
    [
      closeJoinCommunityModal,
      renderJoinWaitlistFormSubSection,
      containerBackgroundTransparent
    ]
  );

  const imageBannerProps = {
    imageProps: {
      alt: 'Banner Image',
      mobileImgProps: {
        src: eventInfo.bannerImg,
        layout: 'responsive',
        width: 800,
        height: 400,
        objectFit: 'cover'
      }
    },
    title: getBannerHeader().title,
    icon: getBannerHeader().icon,
    rounded: true
  };

  const priceTagText = useMemo(() => {
    const period =
      selectedPricingOption?.recurring?.interval === 'year'
        ? 'month'
        : selectedPricingOption?.recurring?.interval;
    if (isFreeCommunity) {
      return needsApplicationReview ? 'Application Required' : 'FREE';
    } else if (nftOnly) {
      return 'NFT-gated';
    } else if (formattedPricePerMonth) {
      return `${
        selectedPricingOption?.currency?.toUpperCase() || 'USD'
      } ${formattedPricePerMonth}/${period}`;
    } else {
      return '';
    }
  }, [
    formattedPricePerMonth,
    isFreeCommunity,
    needsApplicationReview,
    nftOnly,
    selectedPricingOption?.currency,
    selectedPricingOption?.recurring?.interval
  ]);

  const onWhatsappSignUpPopupClose = () => {
    if (
      whatsappSignUpPopupRef.current &&
      whatsappSignUpPopupRef.current.animate
    ) {
      const swipeDownAnimation = whatsappSignUpPopupRef.current.animate(
        [
          { transform: 'translateY(0)' },
          { transform: 'translateY(100%)' }
        ],
        { duration: 450 }
      );

      swipeDownAnimation.onfinish = () =>
        setIsWhatsappSignUpModalOpened(false);
    } else setIsWhatsappSignUpModalOpened(false);
  };

  useEffect(() => {
    if (showPricing && !selectedPricingOption) {
      setSelectedPricingOption(firstPricingData);
    }
  }, [firstPricingData, selectedPricingOption, showPricing]);

  useEffect(() => {
    const getDiscountedPrice = async (discountCode) => {
      const { data, error } =
        await getCommunityPricingByDiscountCodeService({
          discountCode,
          communityCode,
          entityObjectId: eventObjectId,
          entityType: DISCOUNT_ENTITY_TYPE.EVENT
        });
      if (error) {
        return;
      }
      setDiscountedCodeData(data);
    };

    if (
      discountCodeFromQueryParams &&
      discountCodeFromQueryParams.length > 0
    )
      getDiscountedPrice(discountCodeFromQueryParams);
  }, [communityCode, discountCodeFromQueryParams, eventObjectId]);

  useEffect(() => {
    const fetchPricingData = async () => {
      const { data, error } = await getCommunityPricingServiceV2(
        communityObjectId
      );
      if (error) {
        console.log('Error fetching community data', error);
        return;
      }

      const formattedNewPricingDetails =
        formatNewPricingApiToOldFormat(data);

      formattedNewPricingDetails?.sort(function (a, b) {
        return a?.recurring?.interval_count - b?.recurring?.interval_count;
      });

      setPricingData(formattedNewPricingDetails);
    };
    // if page in fallback it means its being built, so leave in loading state, else set loading false
    if (communityInfo?.stripeProductId) {
      fetchPricingData();
    }
  }, [communityObjectId]);

  useEffect(() => {
    setClientCurrentDate(new Date());
    setClientTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    trackGAEvent('public_event_page_visit', {
      eventId: eventInfo?._id,
      communityCode: communityInfo?.code,
      communityId: communityInfo?._id
    });
  }, []);

  useEffect(() => {
    getPrivateEventData();
  }, [getPrivateEventData, isLoggedIn]);

  useEffect(() => {
    if (shouldStartOutsideRedirectFlow) {
      setShowPreCheckoutModal(false);
    }
  }, [setShowPreCheckoutModal, shouldStartOutsideRedirectFlow]);

  useEffect(() => {
    setLastVisitedEvent(eventInfo, communityInfo);
  }, [communityInfo, eventInfo]);

  useEffect(() => {
    if (isCommunityMember) setIsUserAlreadyEnrolled(true);
  }, [isCommunityMember, setIsUserAlreadyEnrolled]);

  const ticketCardRef = useRef(null);
  const [showStickyFooter, setShowStickyFooter] = useState(false);
  useEffect(() => {
    const handleScrollEvent = () => {
      const ticketCard = ticketCardRef.current;
      if (ticketCard) {
        const ticketCardRect = ticketCard.getBoundingClientRect();
        const ticketCardTop = ticketCardRect.top;
        const ticketCardHeight = ticketCardRect.height;
        setShowStickyFooter(ticketCardTop + ticketCardHeight < 0);
      }
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [ticketCardRef]);

  const bannerImg =
    eventInfo.bannerImg ??
    'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/community-events/event-cover-image-1.png';

  return (
    <>
      <PageMetaTags
        url={metaUrl}
        canonicalUrl={metaUrl}
        title={eventInfo.title}
        description={eventInfo.description}
        imageUrl={bannerImg}
      />
      <BlurredBackground bannerImg={bannerImg} alt={eventInfo.title} />
      <div className="font-poppins">
        <Navbar
          sticky={false}
          isDemoCommunity={isDemoEvent}
          isCommunityMember={isUserEnrolled || isCommunityMember}
          isCommunityAdmin={isCommunityAdmin}
          loading={loading || isLoading}
          handleAdminEdit={handleEditEvents}
          navigateToLearnPortal={goToPortalCta?.onClick}
          navigateToAdminPortal={goToCmPortalCta?.onClick}
        />
        <div className="max-w-[800px] mx-auto min-h-[calc(100vh-64px)] py-32 px-16 md:px-40 md:min-h-[calc(100vh-80px)] lg:px-0">
          {isGtEqMd && (
            <ImageBanner
              {...imageBannerProps}
              rounded={true}
              isGtEqMd={isGtEqMd}
              style={{
                boxShadow: CARD_SHADOW
              }}
            />
          )}
          <TicketCard
            ref={ticketCardRef}
            {...getTicketCardProps()}
            timing={getEventTimingProps()}
            title={eventInfo.title}
            type={eventInfo.type}
            imageBannerProps={imageBannerProps}
            isGtEqMd={isGtEqMd}
            timezone={clientTimezone}
            calendarDetails={calendarDetails}
            onLoginClick={onLoginClick}
            isDataLoading={isLoading}
            isTimeLoading={isTimeLoading}
            priceText={formattedEventPriceText}
            customDateText={eventInfo?.customDateText}
            isDemoCommunity={communityInfo?.isDemo}
            prominentUrlProps={getEventProminentUrlProps()}
          />
          <EventDetail
            community={communityInfo}
            event={eventInfo}
            className="my-24"
            isMobileView={isGtEqMd}
            prominentUrlProps={getEventProminentUrlProps()}
          />
          <CommunityDetail
            community={communityInfo}
            className="my-24"
            typeOfPage={'event'}
          />
          {/* <Footer /> */}
          {/* <StickyOnMobile /> */}
          {/* <ShareModal /> */}
          {/* TODO: */}
          {/* Background color to be taken from Image */}
          {/* See more component */}
          {/* Tap to scroll */}
          {/* Tap to redirect */}
          <ShareModal
            shareUrl={shareUrl}
            title="Share your event"
            open={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
            label="Event public page link"
            shareText={eventInfo.title}
            isMobileView={isGtEqMd}
          />
          {joinCommunityModalOpened && (
            <JoinCommunityFeature
              containerBackgroundTransparent={
                containerBackgroundTransparent
              }
              renderInput={renderCustomJoinWaitlistInput}
              onFinish={closeJoinCommunityModal}
              onEnrollment={setUserEnrolled}
              communityCode={communityInfo?.code}
              setShowSuccessMessageOnToast={setShowSuccessMessageOnToast}
              setMessageOnToast={setMessageOnToast}
            />
          )}
          {shouldStartOutsideRedirectFlow && (
            <FreeCommunityRedirectModal
              onClose={() => {
                setShouldStartOutsideRedirectFlow(false);
              }}
              message="Registration in progress. Please Wait."
            />
          )}
          {showPreCheckoutModal && (
            <PreCheckoutStep
              // modal state
              forceForm={checkoutForm}
              showModal={showPreCheckoutModal}
              onClose={() => setShowPreCheckoutModal(false)}
              //UI Stuff
              // stepperProps={stepperProps}
              title={`Join ${communityInfo?.title}`}
              // title={'Join the NFT Pro Community'}
              // subtitle={'Select your preferred mode of signup'}
              // login data
              selectCheckoutAsLoggedInUser={selectCheckoutAsLoggedInUser}
              deselectCheckoutAsLoggedInUser={
                deselectCheckoutAsLoggedInUser
              }
              isCheckingOutAsLoggedInUser={isCheckingOutAsLoggedInUser}
              formRef={formRef}
              onLoginSubmit={onLoginSubmit}
              onLoginChange={onLoginChange}
              loginError={loginError}
              loginLoading={loginLoading}
              // signup data
              handleCheckoutWithEmail={handleCheckoutWithEmail}
              onSignupEmailChange={onSignupEmailChange}
              onDialCodeIdChange={onDialCodeIdChange}
              onPhoneNumberChange={onPhoneNumberChange}
              signupWithEmailErr={signupWithEmailErr}
              loginInputErrors={loginInputErrors}
              checkoutErrors={signUpErrors}
              signupEmail={signupEmail}
              phoneNumber={phoneNumber}
              dialCodeId={dialCodeId}
              loadingText={loadingText}
              isSigningUpWithEmail={isSigningUpWithEmail}
              isSubmittingSignup={isSubmittingSignup}
              isUserAlreadyEnrolled={isUserAlreadyEnrolled}
              setIsUserAlreadyEnrolled={setIsUserAlreadyEnrolled}
              showPhoneField={false}
              checkoutWithEmailCtaLabel="Continue"
              // error prompt
              promptError={promptError}
              resetPromptError={resetPromptError}
              // payment type
              isFreeCommunity={isFreeCommunity}
              shouldPreventCheckoutRedirect={shouldPreventCheckoutRedirect}
            />
          )}
          <EventFooter className="mt-48 mb-[86px]" />
        </div>
      </div>
      <EventConfirmationModal
        {...getTicketCardProps()}
        title={'You are in!'}
        eventTitle={eventInfo.title}
        communityTitle={communityInfo.title}
        showModal={showConfirmationModal && isCommunityMember}
        calendarDetails={calendarDetails}
        closeModal={() =>
          setShowConfirmationModal(false)
        }></EventConfirmationModal>
      {!isGtEqMd && (
        <BottomSticky
          visible={showStickyFooter}
          title={eventInfo.title}
          timing={getEventTimingProps()}
          {...getTicketCardProps()}
        />
      )}

      {/* Whatsapp member Signup flow */}
      {isWhatsappSignUpModalOpened && (
        <WhatsappSignUpPopup
          ref={whatsappSignUpPopupRef}
          onClose={onWhatsappSignUpPopupClose}
          isCommunityMember={isCommunityMember}
          communityInfo={{ ...communityInfo, pricingData }}
          priceTagText={priceTagText}
          signUpOriginProps={{
            isPaid: eventInfo?.access === 'paid',
            signUpOrigin: SIGNUP_ORIGIN.EVENT_PAGE,
            eventInfo: eventInfo,
            onEventOriginSignUpSuccess: postCommunitySignupProcess,
            onEventSuccessRedirectClick: redirectToLPWithTokens
          }}
        />
      )}
    </>
  );
};

export default EventPublicPage;
