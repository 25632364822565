import { useEffect, useState } from 'react';

import { CARD_SHADOW } from '@/features/EventPublicPage/constants';
import DiscordIcon from '@/components/common/Icons/DiscordIcon';
import Facebook from '@/components/common/IconsV2/Facebook';
import LinkAwareText from '@/components/common/LinkAwareText';
import NextImage from '@/components/common/NextImage';
import { ReadMore } from '@/components/common/ReadMore/ReadMore';
import Slack from '@/components/common/IconsV2/Slack';
import Telegram from '@/components/common/IconsV2/Telegram';
import Whatsapp from '@/components/common/IconsV2/Whatsapp';
import classnames from 'classnames';
import config from '@/utility/config';
import { formatNumber } from '@/utility/helpers';
import { getCommunityDataService } from '@/services/communitiesService';
import { platformTypes } from '@/features/community/constants';
import { resolveCommunityUrl } from '@/pages/portal/utils/events';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

const CommunityDetail = ({
  community,
  className,
  typeOfPage = 'event'
}) => {
  const { isGtEqMd } = useWindowWidthContext();
  const headerText = `This ${typeOfPage} is part of a community`;
  const getPlatformIcon = (platformName) => {
    const customClassNames = 'w-28 h-28';
    const sizeOverrides = { width: 28, height: 28 };
    switch (platformName) {
      case platformTypes.discord:
        return (
          <DiscordIcon
            customClassNames={customClassNames}
            {...sizeOverrides}
          />
        );
      case platformTypes.whatsapp:
        return <Whatsapp {...sizeOverrides} />;
      case platformTypes.telegram:
        return (
          <Telegram
            customClassNames={customClassNames}
            {...sizeOverrides}
          />
        );
      case platformTypes.facebook:
        return <Facebook {...sizeOverrides} />;
      case platformTypes.slack:
        return <Slack {...sizeOverrides} />;
      default:
        return null;
    }
  };

  const [landingPageData, setLandingPageData] = useState(null);
  useEffect(() => {
    if (community.link) {
      getCommunityDataService(community.link).then((res) => {
        setLandingPageData(res.data);
      });
    }
  }, [community]);

  const memberCount = Math.max(
    community.totalMemberCount,
    landingPageData?.totalMemberCount
  );
  const profileImage = landingPageData?.profileImage;
  const showMembers =
    !!memberCount && config.memberHideThreshold < memberCount;
  const mainPlatform = community.platforms[0] ?? {
    name: landingPageData?.platformName
  };

  return (
    <div className={classnames('font-poppins', className)}>
      <div
        className="w-full mx-auto overflow-hidden rounded-28 max-w-800"
        style={{
          boxShadow: CARD_SHADOW
        }}>
        {/* Message */}
        <div className="px-24 py-12 bg-npl-transparent-white-50 md:px-40">
          <p className="m-0 font-medium text-npl-text-icon-on-light-surface-secondary text-label-lg">
            {headerText}
          </p>
        </div>
        {/* Title, desc and benefits */}
        <div className="relative flex flex-col gap-24 px-24 py-32 md:px-40 bg-white-default">
          {/* Community Image */}
          {/* TODO use Community small Image */}
          <a
            href={resolveCommunityUrl(community.link)}
            className="relative max-w-[80px] max-h-[80px] min-w-[80px] min-h-[80px] rounded-[16px] overflow-hidden">
            {profileImage && (
              <NextImage
                alt="community image"
                mobileImgProps={{
                  src: profileImage,
                  layout: 'fill',
                  objectFit: 'cover'
                }}
              />
            )}
          </a>
          {/* Community Title and desc */}
          {/* TODO - Get title, hosted by and members from community object */}
          <div className="">
            <a href={resolveCommunityUrl(community.link)}>
              <h3 className="font-semibold underline text-npl-text-icon-on-light-surface-primary text-heading-md">
                {community.title}
              </h3>
            </a>
            <p className="mt-8 font-medium text-npl-text-icon-on-light-surface-secondary text-label-lg">
              <span className="hidden md:inline">Hosted&nbsp;</span>
              By {community.By}
              {showMembers
                ? ` • ${formatNumber(memberCount)} members`
                : ''}
            </p>
            <ReadMore
              maxHeight={isGtEqMd ? 48 : 92}
              buttonStyling="text-npl-text-icon-on-light-surface-secondary text-label-lg font-semibold underline"
              className="mt-24 font-normal break-words whitespace-pre-line text-body-md text-npl-text-icon-on-light-surface-primary">
              <LinkAwareText text={community.description} />
            </ReadMore>
          </div>
          {/* Community Benefits */}
          <ul className="flex flex-col w-full gap-8 mt-28">
            {mainPlatform?.name && (
              <li
                key={`platform-icon`}
                className="flex flex-row items-center justify-start mt-4 first:mt-0 font-poppins">
                <div className="flex-shrink-0">
                  {getPlatformIcon(mainPlatform.name)}
                </div>
                <span className="ml-8 font-medium capitalize text-label-md text-neutral-10">
                  {mainPlatform.name} Group Chat
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CommunityDetail;
