import {
  getTrackingInformationForBE,
  trackGAEvent
} from '@/utility/analytics';
import {
  getPhoneNumberWithoutDial,
  validatePhoneNumber
} from '@/utility/formHelpers';
import {
  SIGNUP_ORIGIN,
  TRACKING_COMMUNITY_TYPE,
  WHATSAPP_FORM_STATE,
  WHATSAPP_SIGNUP_REQUESTOR
} from '../../constants';

import NextImage from '@/components/common/NextImage';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';
import RoundedButton from '@/components/common/RoundedButton';
import { showToast } from '@/components/common/ToastContainer';
import { EVENT_ACTION_TYPE } from '@/features/EventPublicPage/EventPublicPage';
import { getHighestIntervalPricing } from '@/features/community/utils';
import useStartCheckout from '@/hooks/checkout/useStartCheckout';
import Icon from '@/icons/Icon';
import { submitFreeCommunityEnrollment } from '@/services/communitiesService';
import { communitiesSignUpService } from '@/services/communitiesSignUpService';
import { checkWaPhoneNumber } from '@/services/whatsappService';
import { CHECKOUT_FILL_WHATSAPP_NUMBER } from '@/utility/analyticsConsts';
import { SIGN_UP_ACCESS_TOKEN_SESSION_KEY } from '@/utility/checkoutConstants';
import { getTimezoneId } from '@/utility/dateHelper';
import { formatNumber } from '@/utility/helpers';
import { hashEmail } from '@/utility/stringHelper';
import classNames from 'classnames';
import { useState } from 'react';
import WhatsappUserLoginModal from '../WhatsappUserLoginModal';

const PhoneForm = ({
  signUpFormValues,
  setSignUpFormValues,
  setFormState,
  signUpOriginProps
}) => {
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneNumberInputFocused, setIsPhoneNumberInputFocused] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    communityInfo,
    priceTagText,
    userPhoneNumber,
    isCommunityMember,
    isLoggedIn,
    loggedInUser,
    isWaPhoneNumberTaken,
    isPhoneNumberTaken,
    isActiveUser,
    userEmail,
    discountCodeFromQueryParams
  } = signUpFormValues;
  const {
    title,
    profileImage,
    request_approval,
    isFreeCommunity,
    totalMemberCount,
    _id: communityId,
    isTokenGated,
    code,
    link,
    isPaidCommunity,
    pricingData,
    applicationConfig
  } = communityInfo;
  const { eventInfo, resourceInfo, signUpOrigin } = signUpOriginProps;

  const isCheckoutFlowRequired = request_approval || isPaidCommunity;

  const phoneNumberWithoutDial = validatePhoneNumber(phoneNumber)
    ? getPhoneNumberWithoutDial(phoneNumber)
    : phoneNumber;

  const { onCommunityCheckoutClick } = useStartCheckout({
    communityCode: code,
    slug: link,
    isCommunity: true,
    isFreeCommunity: isFreeCommunity,
    shouldPreventCheckoutRedirect: !isCheckoutFlowRequired,
    requestor: WHATSAPP_SIGNUP_REQUESTOR,
    actionType: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return EVENT_ACTION_TYPE;

      return;
    })(),
    sourceInfo: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return {
          type: 'event',
          origin: eventInfo?._id
        };

      if (signUpOrigin === SIGNUP_ORIGIN.RESOURCE_PAGE)
        return {
          type: 'folder',
          origin: resourceInfo?._id
        };

      return;
    })(),
    discountCodeFromQueryParams
  });

  const onPhoneNumberSubmit = async () => {
    try {
      //phone number client-side validation
      if (!validatePhoneNumber(phoneNumber)) {
        setPhoneNumberErrorMessage('Phone number is invalid');
        return;
      }

      setIsSubmitting(true);

      const { data, error } = await checkWaPhoneNumber({
        communityId,
        phoneNumber: phoneNumber.replace('+', '')
      });

      //phone number server-side validation
      if (error) {
        setPhoneNumberErrorMessage(data.error);
        setIsSubmitting(false);
        return;
      }

      const {
        learnerObjectId,
        isWhatsappGroupMember,
        email,
        isActiveUser
      } = data;

      const { learner } = loggedInUser || {};
      const { _id } = learner || {};
      const isPhoneNumberTaken =
        learnerObjectId && _id !== learnerObjectId;

      setSignUpFormValues((prev) => ({
        ...prev,
        isPhoneNumberTaken: isPhoneNumberTaken,
        isWaPhoneNumberTaken: isPhoneNumberTaken && isWhatsappGroupMember,
        inputPhoneNumber: phoneNumber,
        userEmail: email,
        isActiveUser
      }));

      if (isPhoneNumberTaken) {
        setIsSubmitting(false);
        return;
      }

      //this flow is only applicable for logged-in users
      if (isLoggedIn) {
        //if that user is already a member of that community, that user will be redirected to the success screen
        if (isCommunityMember) {
          setFormState(WHATSAPP_FORM_STATE.SUCCESS);
        } else {
          //if that user is not a member, we can use the current user's email to sign up automatically
          const highestIntervalPrice =
            getHighestIntervalPricing(pricingData);
          const payload = {
            timezone: getTimezoneId(),
            communityCode: code,
            phoneNumber: phoneNumber,
            email: loggedInUser.email,
            fullName: loggedInUser.name,
            captchaToken: null,
            isDirectSignUpEmail: false,
            interval: highestIntervalPrice?.recurring?.interval ?? 'month',
            intervalCount:
              highestIntervalPrice?.recurring?.interval_count ?? 1,
            trackingData: getTrackingInformationForBE(),
            requestor: WHATSAPP_SIGNUP_REQUESTOR,
            priceId: highestIntervalPrice?.id
          };
          const data = await communitiesSignUpService(payload);
          const { access_token: bookingToken, id: bookingId } = data || {};
          sessionStorage.setItem(
            SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
            bookingToken
          );
          setSignUpFormValues((prev) => ({
            ...prev,
            bookingId
          }));

          if (data.error) {
            showToast({ text: data.error.info, type: 'error' });
            throw new Error(data.error.info);
          }

          if (isCheckoutFlowRequired) {
            const data = await onCommunityCheckoutClick({
              communityCode: code,
              interval:
                highestIntervalPrice?.recurring?.interval ?? 'month',
              intervalCount:
                highestIntervalPrice?.recurring?.interval_count ?? 1,
              inputEmail: loggedInUser.email,
              inputPhoneNumber: phoneNumber,
              inputFullName: loggedInUser.name,
              priceId: highestIntervalPrice?.id
            });

            if (data.error) {
              showToast({ text: data.error, type: 'error' });
              throw new Error(data.error);
            }

            return;
          } else {
            if (bookingToken) {
              await submitFreeCommunityEnrollment();
            }
            setFormState(WHATSAPP_FORM_STATE.SUCCESS);
          }
        }
        return;
      }

      //non-logged-in user flow
      if (!isLoggedIn || !isCommunityMember) {
        if (!learnerObjectId) {
          setFormState(WHATSAPP_FORM_STATE.EMAIL);

          trackGAEvent(CHECKOUT_FILL_WHATSAPP_NUMBER, {
            isWhatsappCommunity: true,
            type: isTokenGated
              ? TRACKING_COMMUNITY_TYPE.NFT
              : isFreeCommunity
              ? TRACKING_COMMUNITY_TYPE.FREE
              : TRACKING_COMMUNITY_TYPE.PAID,
            community_name: title,
            community_id: communityId
          });
        }
      }
    } catch (e) {
      setIsSubmitting(false);
      throw new Error(e.message);
    }
  };

  return (
    <div className="w-full max-w-[420px] bg-white-default rounded-28 shadow-npl-styles-shadow-01 p-24 my-24 flex items-center flex-col">
      <div className="w-64 h-64">
        <NextImage
          alt="community-image"
          className="rounded-8"
          mobileImgProps={{
            src: profileImage,
            layout: 'responsive',
            objectFit: 'cover',
            width: 64,
            height: 64
          }}
        />
      </div>
      <div className="my-24 space-y-8 text-center">
        <p className="font-semibold text-heading-md text-npl-text-icon-on-light-surface-primary">
          {title}
        </p>
        <p className="font-medium text-label-lg text-npl-text-icon-on-light-surface-secondary">
          {`${formatNumber(totalMemberCount)} ${
            totalMemberCount === 1 ? 'member' : 'members'
          }`}
          {!isFreeCommunity && priceTagText && ' • '}
          {!isFreeCommunity && priceTagText ? priceTagText : ''}
        </p>
        {request_approval && !applicationConfig?.autoApproval && (
          <p className="text-label-md text-npl-text-icon-on-light-surface-tertiary">
            Approval required
          </p>
        )}
      </div>

      {/* If user is a member with no registered phone number */}
      {isCommunityMember && isLoggedIn && userPhoneNumber && (
        <div className="flex p-16 space-x-12 bg-npl-neutral-light-3 rounded-12 border-1 border-npl-neutral-light-6">
          <div>
            <Icon name="waving-hand" />
          </div>
          <p className="text-body-sm text-npl-text-icon-on-light-surface-primary">
            Hey, you’re a member of this community! Continue with your
            WhatsApp number.
          </p>
        </div>
      )}
      <form
        className="w-full mt-16"
        onSubmit={(e) => {
          e.preventDefault();
          onPhoneNumberSubmit();
        }}>
        <PhoneNumberInput
          phoneNumberPlaceHolder="WhatsApp number"
          customClassNames="mb-16"
          phoneNumber={phoneNumber}
          setPhoneNumber={(updatedPhoneNumber) => {
            setPhoneNumber(updatedPhoneNumber);
            //reset values once users try to change the phone number field value again
            if (updatedPhoneNumber !== phoneNumber) {
              setPhoneNumberErrorMessage('');
              setSignUpFormValues((prev) => ({
                ...prev,
                isPhoneNumberTaken: false,
                isWaPhoneNumberTaken: false,
                inputPhoneNumber: '',
                userEmail: '',
                isActiveUser: false
              }));
            }
          }}
          error={phoneNumberErrorMessage}
          isFloating={true}
          floatingLabel="WhatsApp number"
          onTextFocus={() => setIsPhoneNumberInputFocused(true)}
          onTextBlur={() => setIsPhoneNumberInputFocused(false)}
        />

        <div
          className={classNames(
            'border-1 border-npl-neutral-light-6 bg-npl-neutral-light-3 p-16 rounded-12 mb-16 duration-200 overflow-hidden',
            isPhoneNumberInputFocused || !!phoneNumberWithoutDial
              ? 'max-h-[150px]'
              : 'max-h-0 py-0 border-0 mb-0'
          )}>
          <p className="mb-4 font-medium text-body-sm text-npl-text-icon-on-light-surface-primary">
            Ensure that this is your WhatsApp number
          </p>
          <p className="text-body-xs text-npl-text-icon-on-light-surface-primary">
            Unrecognized numbers may be automatically removed from the
            group.
          </p>
        </div>

        {isPhoneNumberTaken && (
          <WhatsappUserLoginModal
            formState={WHATSAPP_FORM_STATE.PHONE}
            signUpFormValues={signUpFormValues}
            setSignUpFormValues={setSignUpFormValues}
            setFormState={setFormState}
            onClose={() =>
              setSignUpFormValues((prev) => {
                return { ...prev, isPhoneNumberTaken: false };
              })
            }
            showMessage={isPhoneNumberTaken}
            message={`${
              isWaPhoneNumberTaken ? 'WhatsApp number' : 'Phone number'
            } ${
              isActiveUser
                ? `already associated with email ${hashEmail(
                    userEmail
                  )}, please insert another or try logging in.`
                : `is linked to an existing account with email ${hashEmail(
                    userEmail
                  )}`
            }`}
            signUpOriginProps={signUpOriginProps}
          />
        )}

        <RoundedButton
          type="submit"
          displayType="primary"
          customClassNames="w-full flex justify-center"
          onClick={onPhoneNumberSubmit}
          isLoading={isSubmitting}
          disabled={isPhoneNumberTaken}>
          Continue
        </RoundedButton>
      </form>
    </div>
  );
};

export default PhoneForm;
