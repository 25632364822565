import { useState } from 'react';

import {
  checkMember,
  submitFreeCommunityEnrollment
} from '@/services/communitiesService';
import {
  SIGNUP_ORIGIN,
  WHATSAPP_FORM_STATE,
  WHATSAPP_SIGNUP_REQUESTOR
} from '../../constants';

import LoginModal from '@/components/common/LoginModalV2';
import Modal from '@/components/common/Modal';
import { getHighestIntervalPricing } from '@/features/community/utils';
import useStartCheckout from '@/hooks/checkout/useStartCheckout';
import { communitiesSignUpService } from '@/services/communitiesSignUpService';
import { getTrackingInformationForBE } from '@/utility/analytics';
import { SIGN_UP_ACCESS_TOKEN_SESSION_KEY } from '@/utility/checkoutConstants';
import { getTimezoneId } from '@/utility/dateHelper';
import { showToast } from '@/components/common/ToastContainer';
import Icon from '@/icons/index';
import { EVENT_ACTION_TYPE } from '@/features/EventPublicPage/EventPublicPage';

const WhatsappUserLoginModal = ({
  onClose,
  setFormState,
  setSignUpFormValues,
  signUpFormValues,
  formState,
  message,
  showMessage,
  signUpOriginProps
}) => {
  const {
    isActiveUser,
    userEmail,
    communityInfo,
    inputPhoneNumber,
    discountCodeFromQueryParams
  } = signUpFormValues;
  const { eventInfo, resourceInfo, signUpOrigin } = signUpOriginProps;

  const [isRedirectionRequired, setIsRedirectionRequired] =
    useState(false);

  const {
    _id: communityId,
    code,
    request_approval,
    isPaidCommunity,
    isFreeCommunity,
    pricingData,
    slug
  } = communityInfo;

  const isCheckoutFlowRequired = request_approval || isPaidCommunity;

  const { onCommunityCheckoutClick } = useStartCheckout({
    communityCode: code,
    slug: slug,
    isCommunity: true,
    isFreeCommunity: isFreeCommunity,
    shouldPreventCheckoutRedirect: !isCheckoutFlowRequired,
    requestor: WHATSAPP_SIGNUP_REQUESTOR,
    actionType: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return EVENT_ACTION_TYPE;

      return;
    })(),
    sourceInfo: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return {
          type: 'event',
          origin: eventInfo?._id
        };

      if (signUpOrigin === SIGNUP_ORIGIN.RESOURCE_PAGE)
        return {
          type: 'folder',
          origin: resourceInfo?._id
        };

      return;
    })(),
    discountCodeFromQueryParams
  });

  const postLoginCallback = async (response) => {
    try {
      const { data } = await checkMember(communityId);
      const { isSubscriber, whatsappInvitationLink } = data ?? {};
      setIsRedirectionRequired(true);

      //if the newly logged-in account is a subscriber, we just redirect this user to the success form page
      if (isSubscriber) {
        setSignUpFormValues((prev) => ({
          ...prev,
          whatsappInvitationLink,
          isCommunityMember: isSubscriber,
          isLoggedIn: true
        }));
        setIsRedirectionRequired(false);
      } else {
        const highestIntervalPrice =
          getHighestIntervalPricing(pricingData);

        const { user } = response;

        if (!user) {
          return;
        }

        const { email, learner } = user;
        const { phoneNumber } = learner || {};
        const payload = {
          timezone: getTimezoneId(),
          communityCode: code,
          phoneNumber: phoneNumber ?? inputPhoneNumber, //have to override the input phone number in the logged-in user case
          email: email,
          captchaToken: null,
          isDirectSignUpEmail: false,
          interval: highestIntervalPrice?.recurring?.interval ?? 'month',
          intervalCount:
            highestIntervalPrice?.recurring?.interval_count ?? 1,
          trackingData: getTrackingInformationForBE(),
          requestor: WHATSAPP_SIGNUP_REQUESTOR,
          priceId: highestIntervalPrice?.id
        };
        const data = await communitiesSignUpService(payload);
        const { access_token: bookingToken, id: bookingId } = data || {};
        sessionStorage.setItem(
          SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
          bookingToken
        );

        setSignUpFormValues((prev) => ({
          ...prev,
          bookingId
        }));

        if (data.error) {
          showToast({ text: data.error.info, type: 'error' });
          throw new Error(data.error.info);
        }

        if (isCheckoutFlowRequired) {
          const data = await onCommunityCheckoutClick({
            communityCode: code,
            interval: highestIntervalPrice?.recurring?.interval ?? 'month',
            intervalCount:
              highestIntervalPrice?.recurring?.interval_count ?? 1,
            inputEmail: email,
            inputPhoneNumber: inputPhoneNumber,
            priceId: highestIntervalPrice?.id
          });

          if (data.error) {
            showToast({ text: data.error, type: 'error' });
            throw new Error(data.error);
          }

          return;
        } else {
          if (bookingToken) {
            await submitFreeCommunityEnrollment();
          }
        }
      }

      //if a user has logged in, we already have all required information (phone number and email) for the enrollment
      setFormState(WHATSAPP_FORM_STATE.SUCCESS);
    } catch (e) {
      throw new Error(e.message);
    } finally {
      onClose();
    }
  };

  return (
    <Modal open size="md" customInnerClass="!px-16" onClose={onClose}>
      <p className="font-semibold text-heading-sm text-npl-text-icon-on-light-surface-primary">
        Login
      </p>
      {showMessage && (
        <div className="flex p-16 mt-32 space-x-12 border-1 border-npl-yellow-light-6 rounded-12 bg-npl-yellow-light-3">
          <div>
            <Icon name="error-alert-warning" />
          </div>
          <div className="text-body-sm text-npl-text-icon-on-light-surface-primary">
            {message}
          </div>
        </div>
      )}
      <div className="mt-32">
        <LoginModal
          isDisabledCta={false}
          postLoginCallback={postLoginCallback}
          defaultEmail={
            formState === WHATSAPP_FORM_STATE.EMAIL ? userEmail : ''
          }
          isLoading={isRedirectionRequired}
          isResetPassword={!isActiveUser}
        />
      </div>
    </Modal>
  );
};

export default WhatsappUserLoginModal;
