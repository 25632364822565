import IconByName from '@/components/common/IconsV2/IconByName';
import LoadingCircle from '@/components/common/LoadingCircle';
import RoundedButton from '@/components/common/RoundedButton';
import ToolTip from '@/components/common/ToolTip';
import React, { forwardRef } from 'react';
import ImageBanner from '../ImageBanner';

import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import {
  buildCalendarOnClick,
  buildCalendarURL
} from '@/pages/portal/utils/calendar';
import { CARD_SHADOW } from '@/features/EventPublicPage/constants';
import classnames from 'classnames';
import {
  EVENT_LANDING_PAGE_CTA,
  handleDemoStateForCta
} from '@/pages/product-demo/utilsAndContants';
import EventProminentUrl from '@/components/features/EventProminentUrl';

const TicketCard = forwardRef(
  (
    {
      imageBannerProps,
      isGtEqMd,
      title,
      eyebrowText,
      showLoginText,
      primaryCta,
      secondaryCta,
      timing,
      timezone,
      calendarDetails,
      onLoginClick,
      isDataLoading,
      isTimeLoading,
      priceText,
      customDateText,
      isDemoCommunity,
      prominentUrlProps
    },
    ref
  ) => {
    const onLoginClickHandler = (e) => {
      if (isDemoCommunity) {
        return handleDemoStateForCta(EVENT_LANDING_PAGE_CTA);
      }
      onLoginClick(e);
    };
    return (
      <div
        className="w-full flex flex-col bg-no-repeat md:flex-row"
        style={{
          filter: `drop-shadow(0px 0px 2px rgba(27, 27, 24, 0.1)) drop-shadow(0px 4px 6px rgba(27, 27, 24, 0.04)) drop-shadow(0px 8px 16px rgba(27, 27, 24, 0.04))`
        }}
        ref={ref}>
        {!isGtEqMd && (
          <ImageBanner
            {...imageBannerProps}
            isGtEqMd={isGtEqMd}
            rounded={true}
            style={{
              boxShadow: CARD_SHADOW,
              borderRadius: isGtEqMd ? '28px' : '28px 28px 0 0'
            }}
          />
        )}
        <div
          className={classnames(
            'p-24 pt-32 flex-grow md:p-40 md:pb-24 bg-no-repeat ',
            isGtEqMd ? 'rounded-tl-28 rounded-bl-28' : 'rounded-0'
          )}
          style={
            !isGtEqMd
              ? {
                  backgroundSize: `50% 100%`,
                  backgroundImage: `radial-gradient(circle at 0% 100%, transparent 20px, white 20px), radial-gradient(circle at 100% 100%, transparent 20px , white 20px)`,
                  backgroundPosition: `top left, right`
                }
              : {
                  backgroundSize: `100% 50%`,
                  backgroundImage: `radial-gradient(circle at 100% 0, transparent 20px, white 20px), radial-gradient(circle at 100% 100%, transparent 20px , white 20px)`,
                  backgroundPosition: `top left, bottom`
                }
          }>
          <h2
            className="mb-24 font-semibold text-heading-xl text-neutral-10"
            data-testid="title">
            {title}
          </h2>
          <div className="flex space-x-16">
            <div className="w-64 overflow-hidden text-center rounded-12 flex-shrink-0">
              <div className="bg-[#523800]">
                <p className="py-4 font-semibold text-overline-sm text-neutral-99">
                  {timing.month.slice(0, 3)}
                </p>
              </div>
              <div className="border-t-0 rounded-br-12 rounded-bl-12 border-1 border-neutral-90">
                <p className="py-4 font-semibold text-heading-md text-neutral-10">
                  {timing.day}
                </p>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex items-center mb-8">
                <p className="mr-8 font-medium text-label-md text-neutral-10">
                  {customDateText
                    ? customDateText
                    : `${timing.weekday}, ${timing.day} ${timing.month}`}
                </p>
              </div>

              {!isTimeLoading && (
                <div className="flex items-center gap-8 mb-8 font-medium text-label-md text-neutral-50">
                  <span>{`${timing.startTime} - ${timing.endTime}`}</span>
                  <ToolTip
                    direction="bottom"
                    text={
                      <span className="w-[178px]">
                        <span className="whitespace-nowrap">
                          Shown in local time zone:
                        </span>
                        <br />
                        <span>{timezone}</span>
                      </span>
                    }>
                    <IconByName
                      name="info-circle-outline"
                      customClassNames="w-16 h-16 fill-neutral-50"
                    />
                  </ToolTip>
                </div>
              )}
              {prominentUrlProps && prominentUrlProps?.text && (
                <div className="w-full mb-8">
                  <EventProminentUrl {...prominentUrlProps} />
                </div>
              )}
              {!!priceText && (
                <div className="flex items-center mb-8 font-medium text-label-md text-neutral-50">
                  <span className="mr-8">
                    <IconByName name="price-tag" fill="#75777A" />
                  </span>
                  {priceText}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-[2px] relative flex justify-center items-center md:h-auto md:w-[2px] md:min-w-[2px]">
          <div
            className="w-full h-[2px] md:h-full md:w-[2px]"
            style={
              !isGtEqMd
                ? {
                    width: '87%',
                    backgroundImage: `linear-gradient(to right, #fff 5%, #fff 50%, rgba(255,255,255,0) 50%)`,
                    backgroundPosition: `-2.5px 0`,
                    backgroundSize: `9px 4px`,
                    backgroundRepeat: `repeat-x`
                  }
                : {
                    height: '79.5%',
                    backgroundImage: `linear-gradient(#fff 5%, #fff 50%, rgba(255,255,255,0) 50%)`,
                    backgroundPosition: `0 -2.5px`,
                    backgroundSize: `4px 9px`,
                    backgroundRepeat: `repeat-y`
                  }
            }
          />
        </div>
        <div
          className={classnames(
            'p-24 flex-shrink-0 flex flex-col justify-end md:w-[280px] md:max-w-[280px] bg-no-repeat',
            isGtEqMd ? 'rounded-tr-28 rounded-br-28' : 'rounded-b-28'
          )}
          style={
            !isGtEqMd
              ? {
                  backgroundSize: `50% 100%`,
                  backgroundImage: `radial-gradient(circle at 0% 0, transparent 20px, white 20px), radial-gradient(circle at 100% 0%, transparent 20px , white 20px)`,
                  backgroundPosition: `top left, right`
                }
              : {
                  backgroundSize: `100% 50%`,
                  backgroundImage: `radial-gradient(circle at 0% 0, transparent 20px, white 20px), radial-gradient(circle at 0% 100%, transparent 20px , white 20px)`,
                  backgroundPosition: `top left, bottom`
                }
          }>
          {isDataLoading ? (
            <LoadingCircle />
          ) : (
            <>
              {eyebrowText && (
                <p className="mb-16 text-center text-label-md text-neutral-50">
                  {eyebrowText}
                </p>
              )}
              <div className="mb-16 space-y-16">
                {primaryCta && primaryCta.isCalendarCta ? (
                  <HeadlessDropdown
                    placement="top-start"
                    renderField={() => (
                      <RoundedButton
                        displayType={primaryCta?.displayType}
                        size="lg"
                        customClassNames="w-full flex justify-center">
                        <div className="flex items-center">
                          <IconByName name="calendar-icon" />
                          <span className="ml-6 !text-neutral-10">
                            {primaryCta?.text ?? 'Add to calendar'}
                          </span>
                        </div>
                      </RoundedButton>
                    )}
                    renderOptions={({ closeDropdown }) => (
                      <div
                        className="bg-white-default w-[150px] py-8 px-12 rounded-8 flex flex-col gap-4 text-neutral-10 text-14"
                        style={{
                          boxShadow: `0 3px 28px rgb(0 0 0 / 8%`
                        }}>
                        <a
                          href={buildCalendarURL(calendarDetails, 'apple')}
                          onClick={(e) => {
                            buildCalendarOnClick(e);
                            closeDropdown();
                          }}
                          className="py-2 hover:text-primary">
                          Apple Calendar
                        </a>
                        <a
                          href={buildCalendarURL(
                            calendarDetails,
                            'google'
                          )}
                          onClick={(e) => {
                            buildCalendarOnClick(e);
                            closeDropdown();
                          }}
                          className="py-2 hover:text-primary">
                          Google
                        </a>
                        <a
                          href={buildCalendarURL(
                            calendarDetails,
                            'outlook'
                          )}
                          onClick={(e) => {
                            buildCalendarOnClick(e);
                            closeDropdown();
                          }}
                          className="py-2 hover:text-primary">
                          Outlook
                        </a>
                        <a
                          href={buildCalendarURL(
                            calendarDetails,
                            'outlookcom'
                          )}
                          onClick={(e) => {
                            buildCalendarOnClick(e);
                            closeDropdown();
                          }}
                          className="py-2 hover:text-primary">
                          Outlook.com
                        </a>
                        <a
                          href={buildCalendarURL(calendarDetails, 'yahoo')}
                          onClick={(e) => {
                            buildCalendarOnClick(e);
                            closeDropdown();
                          }}
                          className="py-2 hover:text-primary">
                          Yahoo
                        </a>
                      </div>
                    )}
                  />
                ) : (
                  primaryCta && (
                    <RoundedButton
                      data-testid="epp-primary-cta"
                      displayType={primaryCta?.displayType}
                      size="lg"
                      customClassNames="w-full flex justify-center"
                      disabled={!!primaryCta.disabled}
                      onClick={primaryCta.onClick}>
                      {primaryCta.text}
                    </RoundedButton>
                  )
                )}
                {secondaryCta && (
                  <RoundedButton
                    displayType={secondaryCta.displayType}
                    size="lg"
                    customClassNames="w-full flex justify-center"
                    onClick={secondaryCta.onClick}>
                    {secondaryCta.text}
                  </RoundedButton>
                )}
              </div>
              {showLoginText && (
                <div className="p-8 text-center text-label-md text-neutral-50">
                  Already a member?{' '}
                  <span
                    role="button"
                    tabIndex="0"
                    className="font-semibold text-yellow-50"
                    onClick={onLoginClickHandler}>
                    Log in
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

TicketCard.displayName = 'TicketCard';

export default TicketCard;
