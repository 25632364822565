import React from 'react';
import PropTypes from 'prop-types';
import LoadingCircle from '@/components/common/LoadingCircle';
import Modal from '@/components/common/Modal';
import style from './FreeCommunityRedirectModal.module.scss';

const FreeCommunityRedirectModal = ({ onClose, message }) => {
  const baseClassNames = '';
  const containerClassNames = style.modal;

  return (
    <div className={baseClassNames}>
      <Modal
        customContainerClass={containerClassNames}
        open={true}
        onClose={onClose}>
        <LoadingCircle />
        <p className={style.text}>{message}</p>
      </Modal>
    </div>
  );
};

FreeCommunityRedirectModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: 'Redirecting you to the portal. Please Wait.'
};

export default FreeCommunityRedirectModal;
