/**
 * Format price in cents to a dollar with correct separator
 * @param priceCents
 * @returns {number}
 */
export const formatPriceCents = (priceCents = 0) => {
  try {
    const price = priceCents / 100;
    return price.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  } catch {
    return '';
  }
};
