import PropTypes from 'prop-types';
import React from 'react';

const NasioGrayscaleIcon = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8484 4.85645C14.8484 6.43368 13.5698 7.71227 11.9926 7.71227C10.4153 7.71227 9.13672 6.43368 9.13672 4.85645C9.13672 3.27921 10.4153 2.00061 11.9926 2.00061C13.5698 2.00061 14.8484 3.27921 14.8484 4.85645Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M2.76505 13.7448C1.74498 12.7247 1.74498 11.0709 2.76505 10.0508C3.78511 9.03077 5.43895 9.03077 6.45903 10.0508L11.9994 15.5911L17.5398 10.0508C18.5598 9.03076 20.2137 9.03076 21.2337 10.0508C22.2538 11.0709 22.2538 12.7247 21.2337 13.7448L13.8942 21.0844C13.8787 21.1007 13.863 21.1168 13.847 21.1328C13.3163 21.6635 12.614 21.9181 11.9186 21.8966C11.904 21.8961 11.8893 21.8955 11.8747 21.8948C11.2482 21.8652 10.6302 21.6112 10.1517 21.1328C10.1355 21.1166 10.1195 21.1002 10.1038 21.0837L2.76505 13.7448Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M39.6971 4.18323H44.1535C44.4121 4.18323 44.5352 4.30662 44.5352 4.56573V21.2599C44.5352 21.4943 44.4121 21.6177 44.1535 21.6177H39.7956C39.574 21.6177 39.4017 21.5067 39.2662 21.2846L34.8836 13.4248V21.2599C34.8836 21.4943 34.7605 21.6177 34.502 21.6177H30.0332C29.8362 21.6177 29.6885 21.445 29.6885 21.2599V4.56573C29.6885 4.30662 29.7992 4.18323 30.0332 4.18323H34.4034C34.6867 4.18323 34.8836 4.29427 34.9821 4.51637L39.34 12.3637V4.56573C39.34 4.36831 39.5371 4.18323 39.6971 4.18323Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M62.9881 21.6177H57.9898C57.7314 21.6177 57.5591 21.5067 57.4606 21.2846L56.7097 18.6318H51.97L51.2067 21.2846C51.1082 21.5067 50.9359 21.6177 50.6774 21.6177H45.6915C45.4946 21.6177 45.3961 21.4696 45.4576 21.2846L51.1575 4.51637C51.256 4.29428 51.4529 4.18323 51.7607 4.18323H56.9066C57.2144 4.18323 57.4114 4.29428 57.5098 4.51637L63.2097 21.2846C63.2712 21.445 63.185 21.6177 62.9881 21.6177ZM52.9794 15.0166H55.7001L54.3336 10.2168L52.9794 15.0166Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M63.333 9.43953C63.333 6.07108 65.869 3.80078 69.7099 3.80078C71.4949 3.80078 73.7848 4.54109 75.3606 5.62688C75.5945 5.77495 75.6683 5.92301 75.5576 6.07108L73.9694 9.63695C73.8833 9.9084 73.7355 9.98243 73.514 9.8467C72.1352 9.05704 70.9533 8.61285 69.9685 8.52648C69.1929 8.5388 68.6759 8.90897 68.6759 9.40251C68.6759 9.83437 69.2914 10.2663 70.5225 10.6735C71.7535 11.0806 72.8984 11.6728 73.9572 12.4501C75.0282 13.2275 75.8161 14.6465 75.8161 16.3862C75.8284 19.7547 73.2062 22.0003 69.3283 22.0003C67.2108 22.0003 64.9704 21.334 63.7392 20.3346C63.5177 20.1619 63.4438 20.0138 63.53 19.8781L65.1181 16.3122C65.2043 16.0407 65.352 15.979 65.5736 16.1147C66.8539 16.8427 68.0235 17.2375 69.0821 17.3239C69.8823 17.3239 70.4117 16.9045 70.4117 16.4108C70.3993 15.979 69.7839 15.5472 68.5528 15.1154C67.3217 14.6959 66.1891 14.1036 65.1673 13.351C64.1333 12.5736 63.333 11.1546 63.333 9.43953Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M79.5049 22.0003C80.7233 22.0003 81.711 21.0104 81.711 19.7892C81.711 18.5681 80.7233 17.5781 79.5049 17.5781C78.2865 17.5781 77.2988 18.5681 77.2988 19.7892C77.2988 21.0104 78.2865 22.0003 79.5049 22.0003Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M89.0898 19.0521C89.0898 20.6803 87.7728 22.0002 86.1483 22.0002C84.5239 22.0002 83.207 20.6803 83.207 19.0521V7.25963C83.207 5.63143 84.5239 4.31152 86.1483 4.31152C87.7728 4.31152 89.0898 5.63143 89.0898 7.25963V19.0521Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.233 13.1559C108.233 18.0404 104.282 22.0002 99.4088 22.0002C94.5347 22.0002 90.585 18.0404 90.585 13.1559C90.585 8.27126 94.5347 4.31152 99.4088 4.31152C104.282 4.31152 108.233 8.27126 108.233 13.1559ZM103.495 12.9609C103.495 15.2288 101.661 17.0672 99.3983 17.0672C97.1355 17.0672 95.3013 15.2288 95.3013 12.9609C95.3013 10.693 97.1355 8.8546 99.3983 8.8546C101.661 8.8546 103.495 10.693 103.495 12.9609Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
};

NasioGrayscaleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

NasioGrayscaleIcon.defaultProps = {
  width: '110',
  height: '24'
};

export default NasioGrayscaleIcon;
