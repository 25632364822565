import { useAuthContext } from '@/contexts/AuthContext';
import { countryList } from '@/data/CoursePage/wisenetMapCountryList';
import {
  isValidPhoneFormat,
  isValidEmailFormat
} from '../utility/validation';
import {
  emailSectionStaticProps,
  ERROR_MODAL,
  EXISTING_ENROLLMENT_MODAL,
  NEW_ENROLLMENT_MODAL
} from '@/pages/communities/constants';
import { enrollToCommunityWaitlistService } from '@/services/communitiesService';
import { getUserCountryData } from '@/services/paymentService';
import { useCallback, useEffect, useState } from 'react';
import { getDialCodeById } from '@/utility/formHelpers';
import {
  ERROR_FOR_NO_EMAIL,
  ERROR_FOR_WRONG_EMAIL,
  ERROR_FOR_WRONG_PHONE_NUMBER
} from '@/utility/constants';
import useSetUserCountryDialCodeId from './useSetUserCountryDialCodeId';

// TODO move all relevant imports to the feature dir
// TODO convert Communities Waitlist Page to use JoinCommunityFeature

const useJoinCommunity = ({
  onSubmitCB,
  onEnrollment,
  communityCode,
  onSuccessCB
}) => {
  const { user } = useAuthContext();

  const [email, setEmail] = useState(user?.email || '');
  const [dialCodeId, onDialCodeIdChange] = useState('');
  const [phoneNumber, onPhoneNumberChange] = useState('');
  const [userEnrolled, setUserEnrolled] = useState(false);
  const [modal, setModal] = useState();
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [errorMessagePhoneNumber, setErrorMessagePhoneNumber] =
    useState('');
  const [fetching, setFetching] = useState(false);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  // this is not in a different file because we want to use errorMessageEmail
  const emailValidationCheck = (email) => {
    if (email === '') {
      setErrorMessageEmail(ERROR_FOR_NO_EMAIL);
      return false;
    }
    if (!isValidEmailFormat(email)) {
      setErrorMessageEmail(ERROR_FOR_WRONG_EMAIL);
      return false;
    }
    return true;
  };
  const onSubmit = useCallback(
    async (e) => {
      try {
        onSubmitCB();
        e.preventDefault();

        if (emailValidationCheck(email)) {
          const dialCode = getDialCodeById(dialCodeId);
          const fullPhoneNumber = `${dialCode}${phoneNumber}`;

          if (phoneNumber === '' || isValidPhoneFormat(fullPhoneNumber)) {
            setFetching(true);
            const profileData = {
              email,
              country: user?.country,
              communityCode,
              phoneNumber: fullPhoneNumber,
              dialCode
            };

            if (phoneNumber === '') {
              delete profileData['phoneNumber'];
              delete profileData['dialCode'];
            }
            const response = await enrollToCommunityWaitlistService(
              profileData
            );

            if (response.error) {
              throw new Error(response.error);
            } else {
              setModal({
                message: response?.data?.message,
                type: response?.data?.isNewEnrolment
                  ? NEW_ENROLLMENT_MODAL
                  : EXISTING_ENROLLMENT_MODAL
              });
              setUserEnrolled(response?.data?.enrolled);
              onSuccessCB(
                response?.data?.enrolled,
                response?.data?.message
              );
              onEnrollment();
            }
          } else {
            setErrorMessagePhoneNumber(ERROR_FOR_WRONG_PHONE_NUMBER);
            return;
          }
        }
      } catch (err) {
        setModal({ message: err.message, type: ERROR_MODAL });
      } finally {
        setFetching(false);
      }
    },
    [
      onSubmitCB,
      email,
      dialCodeId,
      phoneNumber,
      user?.country,
      communityCode,
      onSuccessCB,
      onEnrollment
    ]
  );

  const getEmailProps = useCallback(() => {
    return {
      ...emailSectionStaticProps,
      email,
      isSubmitting: fetching || userEnrolled,
      setEmail,
      onSubmit,
      onDialCodeIdChange,
      onPhoneNumberChange,
      dialCodeId,
      phoneNumber,
      errorMessageEmail,
      errorMessagePhoneNumber
    };
  }, [
    dialCodeId,
    email,
    errorMessageEmail,
    errorMessagePhoneNumber,
    fetching,
    onSubmit,
    phoneNumber,
    userEnrolled
  ]);

  useSetUserCountryDialCodeId({
    dialCodeId,
    setDialCodeId: onDialCodeIdChange
  });

  useEffect(() => {
    if (email !== '' && errorMessageEmail === ERROR_FOR_NO_EMAIL) {
      setErrorMessageEmail('');
    }
    if (email !== '' && errorMessageEmail === ERROR_FOR_WRONG_EMAIL) {
      setErrorMessageEmail('');
    }
    // we are doing this because we do not want it to update when error message is set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (
      phoneNumber !== '' &&
      errorMessagePhoneNumber === ERROR_FOR_WRONG_PHONE_NUMBER
    ) {
      setErrorMessagePhoneNumber('');
    }
    //we are doing this because we do not want it to update when error message is set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);
  return {
    userEnrolled,
    modal,
    closeModal,
    onSubmit,
    getEmailProps,
    errorMessageEmail
  };
};

export default useJoinCommunity;
