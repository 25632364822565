import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useJoinCommunity from '@/hooks/useJoinCommunity';
import WaitlistResponseModalContent from '@/components/common/WaitlistResponseModalContent';
import {
  ERROR_MODAL,
  EXISTING_ENROLLMENT_MODAL
} from '@/pages/communities/constants';
import Modal from '@/components/common/Modal';
import { ENROLLED_STATUS, TIME_FOR_SHOWING_THE_TOAST } from './constants';

const JoinCommunityFeature = ({
  renderInput,
  onFinish,
  onEnrollment,
  communityCode,
  containerBackgroundTransparent,
  setShowSuccessMessageOnToast,
  setMessageOnToast
}) => {
  const [showInputModal, setShowInputModal] = useState(true);

  const showSuccessToast = (messageOnToast) => {
    setMessageOnToast(messageOnToast);
    setShowSuccessMessageOnToast(true);

    setTimeout(() => {
      setShowSuccessMessageOnToast(false);
    }, TIME_FOR_SHOWING_THE_TOAST);
  };
  const onSuccessCB = (response, messageOnToast) => {
    if (response) {
      showSuccessToast(messageOnToast);
      if (communityCode) {
        let data = {
          [communityCode]: ENROLLED_STATUS
        };
        let existingData = sessionStorage.getItem('communityCode');
        if (existingData) {
          existingData = JSON.parse(existingData);
          data = { ...existingData, ...data };
        }
        sessionStorage.setItem('communityCode', JSON.stringify(data));
      }
      setShowInputModal(false);
    }
  };
  // this is there for the old modal so don't remove it
  const onSubmitCB = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { modal, closeModal, getEmailProps } = useJoinCommunity({
    onSubmitCB,
    onEnrollment,
    communityCode,
    onSuccessCB
  });

  const onClose = useCallback(() => {
    closeModal();
    onFinish();
  }, [closeModal, onFinish]);

  return (
    <div className="c-JoinCommunityFeature">
      {/* Custom Input */}
      {showInputModal && <div>{renderInput(getEmailProps())}</div>}
      {/* -- MODALS -- */}
      {containerBackgroundTransparent === false && (
        <Modal size="md" open={!!modal} onClose={onClose}>
          <WaitlistResponseModalContent
            apiError={modal?.type === ERROR_MODAL}
            alreadySubmittedError={
              modal?.type === EXISTING_ENROLLMENT_MODAL
            }
            NEW_ENROLLMENT_MODAL
            dismiss={closeModal}
          />
        </Modal>
      )}
    </div>
  );
};

JoinCommunityFeature.propTypes = {
  renderInput: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  onEnrollment: PropTypes.func.isRequired,
  communityCode: PropTypes.string.isRequired
};

export default JoinCommunityFeature;
