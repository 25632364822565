import { forwardRef, useCallback, useEffect, useState } from 'react';
import LoadingCircle from '@/components/common/LoadingCircle';
import { showToast } from '@/components/common/ToastContainer';
import { useAuthContext } from '@/contexts/AuthContext';
import Icon from '@/icons/Icon';
import { checkMember } from '@/services/communitiesService';
import { checkWaPhoneNumber } from '@/services/whatsappService';
import NasIOLogoWithText from '@/components/common/IconsV2/NasIOLogoWithText';
import BlurredBackground from '../EventPublicPage/components/BlurredBackground/BlurredBackground';
import EmailForm from './components/EmailForm';
import PhoneForm from './components/PhoneForm';
import SuccessForm from './components/SuccessForm';
import OneClickForm from './components/OneClickForm';
import style from './WhatsappSignUpPopup.module.scss';
import classNames from 'classnames';
import { WHATSAPP_FORM_STATE } from './constants';

const FormComponent = ({ formState, ...props }) => {
  let StatedComponent = PhoneForm;
  switch (formState) {
    case WHATSAPP_FORM_STATE.PHONE:
      StatedComponent = PhoneForm;
      break;
    case WHATSAPP_FORM_STATE.EMAIL:
      StatedComponent = EmailForm;
      break;
    case WHATSAPP_FORM_STATE.SUCCESS:
      StatedComponent = SuccessForm;
      break;
    case WHATSAPP_FORM_STATE.ONE_CLICK:
      StatedComponent = OneClickForm;
      break;
  }
  return <StatedComponent {...props}></StatedComponent>;
};

const className = 'c-WhatsappSignUpPopup';
const WhatsappSignUpPopup = (
  {
    onClose,
    isCommunityMember,
    communityInfo,
    priceTagText,
    signUpOriginProps = {},
    discountCodeFromQueryParams
  },
  ref
) => {
  const [formState, setFormState] = useState(WHATSAPP_FORM_STATE.PHONE);
  const [isFetching, setIsFetching] = useState(true);
  const { isLoggedIn, user } = useAuthContext();
  const [signUpFormValues, setSignUpFormValues] = useState({
    isLoggedIn,
    loggedInUser: user,
    communityInfo,
    priceTagText,
    isCommunityMember,
    userPhoneNumber: '',
    inputPhoneNumber: '',
    inputPhoneNumberIsOfPendingUser: false,
    inputPhoneNumberIsWaCommunityParticipant: false,
    inputEmail: '',
    whatsappInvitationLink: '',
    isWaPhoneNumberTaken: false,
    discountCodeFromQueryParams
  });

  const isSuccessFormActive = formState === WHATSAPP_FORM_STATE.SUCCESS;

  const getInitialScreenState = useCallback(async () => {
    try {
      //if the user is not logged in, we don't need to update form state
      if (!isLoggedIn) {
        return;
      }

      const { data, error } = await checkMember(communityInfo._id);
      if (error) {
        throw new Error(error);
      }
      const { phoneNumber, isSubscriber, whatsappInvitationLink } = data;
      setSignUpFormValues((prev) => ({
        ...prev,
        userPhoneNumber: phoneNumber,
        isCommunityMember: isSubscriber,
        whatsappInvitationLink,
        isLoggedIn,
        loggedInUser: user
      }));

      if (!isSubscriber && phoneNumber) {
        setFormState(WHATSAPP_FORM_STATE.ONE_CLICK);
      }

      //if the user has a phone number and is a member of a community, we just redirect to the success page
      if (isSubscriber && phoneNumber) {
        //make sure the current user's phone number is owned by himself/herself
        const { data } = await checkWaPhoneNumber({
          communityId: communityInfo._id,
          phoneNumber: phoneNumber.replace('+', '')
        });
        const { learnerObjectId, isWhatsappGroupMember } = data;
        const { learner } = user || {};
        const { _id } = learner || {};
        if (learnerObjectId !== _id) {
          setSignUpFormValues((prev) => ({
            ...prev,
            isWaPhoneNumberTaken: isWhatsappGroupMember, //the phone number has been taken and already used for a WA group
            isPhoneNumberTaken: true
          }));
        }
        setFormState(WHATSAPP_FORM_STATE.SUCCESS);
      }
    } catch (e) {
      showToast({ type: 'error', text: e.message });
    } finally {
      //wait for all states updated to avoid flickering between screen changes
      setTimeout(() => {
        setIsFetching(false);
      }, 700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    setSignUpFormValues((prev) => ({
      ...prev,
      communityInfo
    }));
  }, [communityInfo]);

  useEffect(() => {
    getInitialScreenState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    getInitialScreenState();

    //disable the primary scroller while the popup is opening
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //price tag can be changed dynamically, so we need to update the price tag text regularly
  useEffect(
    () => setSignUpFormValues((prev) => ({ ...prev, priceTagText })),
    [priceTagText]
  );

  if (isFetching) {
    return <LoadingCircle withFullScreen={true} />;
  }

  return (
    <div
      ref={ref}
      className={classNames(
        className,
        style[className],
        'w-screen h-screen fixed z-[1000] top-0 left-0 bg-white-default'
      )}>
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex flex-col items-center w-full">
          <div className="flex items-center">
            <p className="mr-8 font-medium text-heading-md text-npl-text-icon-on-light-surface-primary">
              Join on <span className="text-[#2A9852]">WhatsApp</span>
            </p>
            <Icon name="whatsapp-colored" />
          </div>
          <div className="flex justify-center w-full px-16">
            <FormComponent
              formState={formState}
              setFormState={setFormState}
              signUpFormValues={signUpFormValues}
              setSignUpFormValues={setSignUpFormValues}
              signUpOriginProps={signUpOriginProps}></FormComponent>
          </div>
          {/* DEV-547 Hide close icon when on success form */}
          {!isSuccessFormActive && (
            <div
              tabIndex={0}
              role="button"
              className="flex items-center px-16 py-6 mx-auto space-x-8 rounded-full cursor-pointer w-fit mix-blend-multiply hover:bg-npl-neutral-light-3"
              onClick={onClose}>
              <p className="font-medium text-button-md text-npl-text-icon-on-light-surface-tertiary">
                Learn more
              </p>
              <div className="-rotate-90">
                <Icon name="chevron-down" fill="rgba(27, 27, 24, 0.5)" />
              </div>
            </div>
          )}
        </div>
        <div className="absolute flex flex-col items-center top-[21px] left-16">
          <a href="/">
            <NasIOLogoWithText opacity="0.25" />
          </a>
        </div>
      </div>
      {/* DEV-547 Hide close icon when on success form */}
      {!isSuccessFormActive && (
        <div
          tabIndex={0}
          role="button"
          className="absolute p-12 rounded-full cursor-pointer top-16 right-16 mix-blend-multiply hover:bg-npl-neutral-light-3"
          onClick={onClose}>
          <Icon width={18} height={18} name="close" />
        </div>
      )}

      <BlurredBackground
        bannerImg="https://d2oi1rqwb0pj00.cloudfront.net/nasIO/onboarding/jpg/default-community-logo-orange.jpg"
        alt="WhatsApp sign-up banner"
      />
    </div>
  );
};

export default forwardRef(WhatsappSignUpPopup);
