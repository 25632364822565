import React from 'react';
import NextImage from 'next/image';

const BlurredBackground = ({ bannerImg, alt }) => {
  return (
    <div className="fixed top-0 left-0 z-[-1] w-full h-full bg-npl-neutral-light-2">
      <NextImage
        src={bannerImg}
        alt={alt ?? bannerImg}
        className="w-full h-full blur-3xl opacity-20"
        layout="fill"
        quality={1}
      />
    </div>
  );
};

export default BlurredBackground;
