import {
  WHATSAPP_FORM_STATE,
  WHATSAPP_SIGNUP_REQUESTOR
} from '../../constants';

import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { showToast } from '@/components/common/ToastContainer';
import { useAuthContext } from '@/contexts/AuthContext';
import { EVENT_ACTION_TYPE } from '@/features/EventPublicPage/EventPublicPage';
import { getHighestIntervalPricing } from '@/features/community/utils';
import useStartCheckout from '@/hooks/checkout/useStartCheckout';
import { submitFreeCommunityEnrollment } from '@/services/communitiesService';
import { communitiesSignUpService } from '@/services/communitiesSignUpService';
import { getTrackingInformationForBE } from '@/utility/analytics';
import { SIGN_UP_ACCESS_TOKEN_SESSION_KEY } from '@/utility/checkoutConstants';
import { getTimezoneId } from '@/utility/dateHelper';
import { useState } from 'react';
import { SIGNUP_ORIGIN } from '../../constants';
import { formatNumber } from '@/utility/helpers';

const OneClickForm = ({
  signUpFormValues,
  setSignUpFormValues,
  setFormState,
  signUpOriginProps
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    communityInfo,
    priceTagText,
    isPhoneNumberTaken,
    userPhoneNumber,
    discountCodeFromQueryParams
  } = signUpFormValues;
  const {
    title,
    profileImage,
    request_approval,
    isFreeCommunity,
    totalMemberCount,
    isPaidCommunity,
    code,
    link,
    pricingData,
    applicationConfig
  } = communityInfo;
  const { eventInfo, resourceInfo, signUpOrigin } = signUpOriginProps;

  const isCheckoutFlowRequired = request_approval || isPaidCommunity;

  const { user } = useAuthContext();

  const { onCommunityCheckoutClick } = useStartCheckout({
    communityCode: code,
    slug: link,
    isCommunity: true,
    isFreeCommunity: isFreeCommunity,
    shouldPreventCheckoutRedirect: !isCheckoutFlowRequired,
    requestor: WHATSAPP_SIGNUP_REQUESTOR,
    actionType: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return EVENT_ACTION_TYPE;

      return;
    })(),
    sourceInfo: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return {
          type: 'event',
          origin: eventInfo?._id
        };

      if (signUpOrigin === SIGNUP_ORIGIN.RESOURCE_PAGE)
        return {
          type: 'folder',
          origin: resourceInfo?._id
        };

      return;
    })(),
    discountCodeFromQueryParams
  });

  const onJoin = async () => {
    try {
      setIsSubmitting(true);

      const highestIntervalPrice = getHighestIntervalPricing(pricingData);

      const { email } = user;
      const payload = {
        timezone: getTimezoneId(),
        communityCode: code,
        phoneNumber: userPhoneNumber,
        email: email,
        captchaToken: null,
        isDirectSignUpEmail: false,
        interval: highestIntervalPrice?.recurring?.interval ?? 'month',
        intervalCount:
          highestIntervalPrice?.recurring?.interval_count ?? 1,
        trackingData: getTrackingInformationForBE(),
        requestor: WHATSAPP_SIGNUP_REQUESTOR,
        priceId: highestIntervalPrice?.id
      };

      const data = await communitiesSignUpService(payload);

      if (data.error) {
        showToast({ text: data.error.info, type: 'error' });
        throw new Error(data.error.info);
      }

      const { access_token: bookingToken, id: bookingId } = data || {};
      sessionStorage.setItem(
        SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
        bookingToken
      );

      setSignUpFormValues((prev) => ({
        ...prev,
        bookingId
      }));

      if (isCheckoutFlowRequired) {
        const data = await onCommunityCheckoutClick({
          communityCode: code,
          interval: highestIntervalPrice?.recurring?.interval ?? 'month',
          intervalCount:
            highestIntervalPrice?.recurring?.interval_count ?? 1,
          inputEmail: email,
          inputPhoneNumber: userPhoneNumber,
          priceId: highestIntervalPrice?.id
        });

        if (data.error) {
          showToast({ text: data.error, type: 'error' });
          throw new Error(data.error);
        }

        return;
      } else {
        if (bookingToken) {
          await submitFreeCommunityEnrollment();
        }

        setFormState(WHATSAPP_FORM_STATE.SUCCESS);
      }
    } catch (e) {
      setIsSubmitting(false);
      throw new Error(e.message);
    }
  };

  return (
    <div className="w-full max-w-[420px] bg-white-default rounded-28 shadow-npl-styles-shadow-01 p-24 my-24 flex items-center flex-col">
      <div className="w-64 h-64">
        <NextImage
          alt="community-image"
          className="rounded-8"
          mobileImgProps={{
            src: profileImage,
            layout: 'responsive',
            objectFit: 'cover',
            width: 64,
            height: 64
          }}
        />
      </div>
      <div className="my-24 space-y-8 text-center">
        <p className="font-semibold text-heading-md text-npl-text-icon-on-light-surface-primary">
          {title}
        </p>
        <p className="font-medium text-label-lg text-npl-text-icon-on-light-surface-secondary">
          {`${formatNumber(totalMemberCount)} ${
            totalMemberCount === 1 ? 'member' : 'members'
          }`}
          {!isFreeCommunity && priceTagText && ' • '}
          {!isFreeCommunity && priceTagText ? priceTagText : ''}
        </p>
        {request_approval && !applicationConfig?.autoApproval && (
          <p className="text-label-md text-npl-text-icon-on-light-surface-tertiary">
            Approval required
          </p>
        )}
      </div>
      <div className="w-full mt-16">
        <RoundedButton
          type="submit"
          displayType="primary"
          customClassNames="w-full flex justify-center"
          onClick={onJoin}
          isLoading={isSubmitting}
          disabled={isPhoneNumberTaken}>
          {isCheckoutFlowRequired ? 'Continue' : 'Join now'}
        </RoundedButton>
      </div>
    </div>
  );
};

export default OneClickForm;
