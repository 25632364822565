import classNames from 'classnames';
import React, { useMemo } from 'react';

import Button from '../../Button';
import Card from '../../Card';
import CheckmarkIcon from '../../Icons/CheckmarkIcon';
import Text from '../../Text';
import FormField from '../FormField';
import Input from '../Input';
import style from './EmailForm.module.scss';
import { dialCodeOptions } from './utils';

const EmailForm = ({
  isSubmitting,
  email,
  setEmail,
  placeholder,
  successMsg,
  onSubmit,
  submitDisplayType,
  containerBackgroundTransparent: isFullScreenModal,
  closeJoinCommunityModal,
  closeModalText,
  ctaTextLabel,
  onDialCodeIdChange,
  dialCodeId,
  onPhoneNumberChange,
  phoneNumber,
  errorMessageEmail,
  errorMessagePhoneNumber
}) => {
  const className = 'c-EmailForm';

  const baseClassNames = classNames(className, style[className]);
  const buttonStyling =
    isFullScreenModal &&
    `mt-12 ${style[`${className}__button-width-style`]}`;
  const flexDirection =
    isFullScreenModal && style[`${className}__form-container-direction`];
  const inputStyleClass =
    isFullScreenModal &&
    `${style[`${className}__input_with_transparent`]}`;
  const inputStyleClassForError =
    errorMessageEmail && style[`${className}__input_with_error`];

  const inputStyleClassForErrorPhone =
    errorMessagePhoneNumber && style[`${className}__input_with_error`];
  const isBtnDisabled = isSubmitting;
  const btnDisplayType = isBtnDisabled
    ? 'disabled'
    : submitDisplayType || 'form-primary';
  const inputStyleForDialCode =
    isFullScreenModal &&
    errorMessagePhoneNumber === '' &&
    `${style[`${className}__select_with_transparent`]} `;
  const inputStyleForDialCodeError =
    errorMessagePhoneNumber &&
    style[`${className}__select_with_transparent_error`];

  const formatteddialCodeOptions = useMemo(
    () =>
      dialCodeOptions.reduce((result, { label, value }) => {
        if (value === Number(dialCodeId)) {
          result.push({ value, label: label.split(' ')[0] });
        } else {
          result.push({ label, value });
        }
        return result;
      }, []),
    [dialCodeId]
  );

  return (
    <div className={baseClassNames}>
      {successMsg ? (
        <Card success>
          <CheckmarkIcon />
          <Text text={successMsg} />
        </Card>
      ) : (
        <form className={baseClassNames} onSubmit={onSubmit} noValidate>
          <div
            className={
              style[`${className}__form-container`] + '  ' + flexDirection
            }>
            {isFullScreenModal && (
              <p className="mb-8 font-medium text-left text-white-default text-14 font-poppins">
                Email
              </p>
            )}
            <Input
              name="email"
              type="email"
              placeholder={placeholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorMessageEmail}
              className={`${inputStyleClass} ${inputStyleClassForError}`}
              customErrorClassName={
                isFullScreenModal &&
                style[`${className}__input_error_transparent`]
              }
            />
            {isFullScreenModal && (
              <p className="mt-16 mb-8 font-medium text-left text-white-default text-14 font-poppins">
                Phone Number
              </p>
            )}
            {isFullScreenModal && (
              <div>
                <div className="flex flex-row">
                  <FormField
                    fieldType="select"
                    name="dialCodeId"
                    onChange={(e) => {
                      onDialCodeIdChange(e.target.value);
                    }}
                    placeholder={'-'}
                    customClassInput={'mr-12 '}
                    value={dialCodeId}
                    dataTestId={className}
                    optionsList={formatteddialCodeOptions}
                    v2
                    className={`${inputStyleForDialCode} ${inputStyleForDialCodeError}`}
                  />
                  <FormField
                    fieldType="input"
                    name="phone"
                    onChange={(e) => {
                      onPhoneNumberChange(e.target.value);
                    }}
                    customClassInput={'flex-1'}
                    value={phoneNumber}
                    placeholder="Enter your phone number"
                    dataTestId={className}
                    className={`${inputStyleClass} ${inputStyleClassForErrorPhone}`}
                    v2
                  />
                </div>

                {errorMessagePhoneNumber && (
                  <p className="font-normal text-left font-poppins text-14 text-error">
                    {errorMessagePhoneNumber}
                  </p>
                )}
              </div>
            )}
            <div className={style[`${className}__submit-btn-container`]}>
              <Button
                disabled={isBtnDisabled}
                customClassNames={buttonStyling}
                fluid
                type="submit"
                displayType={btnDisplayType}>
                {ctaTextLabel}
              </Button>
            </div>
            {isFullScreenModal && (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <p
                onClick={() => closeJoinCommunityModal()}
                className="font-semibold mt-22 text-white-default font-poppins text-14 hover:cursor-pointer hover:underline">
                {closeModalText}{' '}
              </p>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

EmailForm.defaultProps = {
  placeholder: 'Email address'
};

export default EmailForm;
