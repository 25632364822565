import config from '@/utility/config';

const imgBasePath = config.imagesBasePath;
const pageImagesPath = `${imgBasePath}/na-website/communities-page`;

export const pageMetaTagsProps = {
  title: `Learn More About the Communities Subscription at Nas Academy`,
  description: `Launching membership communities for access to subscriber-only content and perks, connecting people around shared interests or goals. Join the waitlist.`,
  url: `${config.siteBaseUrl}/communities`,
  imageUrl: `${pageImagesPath}/png/community-metaPreviewImage.png`
};

export const bannerSectionStaticProps = {
  headline: [
    {
      text: `You're the average of`,
      isNewLine: true
    },
    { text: ` the five people`, isNewLine: true },
    { text: ` around you`, isNewLine: true }
  ],
  cover: {
    alt: 'communities cover',
    mobileImgProps: {
      src: `${pageImagesPath}/png/community_hero_mb.png`,
      width: 414,
      height: 408
    },
    tabletImgProps: {
      src: `${pageImagesPath}/png/community_hero_tb.png`,
      width: 992,
      height: 564
    },
    desktopImgProps: {
      src: `${pageImagesPath}/png/community-heroBgBanner-desktop3.svg`,
      width: 1440,
      height: 564
    }
  }
};

export const emailSectionStaticProps = {
  description: [
    { text: `That's why we're creating`, isNewLine: true },
    { text: 'membership communities' }
  ],
  tag: 'COMING SOON',
  submitBtnText: 'Join waitlist',
  placeholder: 'Enter your email address'
};

export const startACommunityStaticProps = {
  title: 'or build your community with us',
  ctaText: 'Learn More',
  ctaLink: '/application/start-a-community',
  list: [
    {
      title: 'Grow',
      description:
        'Find new followers beyond your audience by tapping into our global network of students.'
    },
    {
      title: 'Engage',
      description:
        'Stay connected to your biggest fans and give them value in the form of classes and live events.'
    },
    {
      title: 'Monetize',
      description:
        'Turn your platform into a reliable revenue stream and boost your brand awareness.'
    }
  ],
  imgData: {
    alt: 'start communities image',
    mobileImgProps: {
      src: `${imgBasePath}/na-website/start-a-community-page/png/start-commity-thumbnail.png`,
      width: 366,
      height: 366
    },
    tabletImgProps: {
      src: `${imgBasePath}/na-website/start-a-community-page/png/start-commity-thumbnail.png`,
      width: 500,
      height: 500
    },
    desktopImgProps: {
      src: `${imgBasePath}/na-website/start-a-community-page/png/start-commity-thumbnail.png`,
      width: 480,
      height: 480
    }
  }
};

export const ERROR_MODAL = 'ERROR_MODAL';
export const EXISTING_ENROLLMENT_MODAL = 'EXISTING_ENROLLMENT_MODAL';
export const NEW_ENROLLMENT_MODAL = 'NEW_ENROLLMENT_MODAL';
export const responseModalSuccessProps = {
  [EXISTING_ENROLLMENT_MODAL]: {
    title: `You’re already added!`,
    description: `We'll let you know as soon as it launches!`
  },
  [NEW_ENROLLMENT_MODAL]: {
    title: `You’ve been added!`,
    description: `We'll let you know as soon as it launches!`
  }
};
export const WAITLIST_MODAL_ERROR_MESSAGE = [
  `There’s error in receiving`,
  `your e-mail`
];

export const newsletterStaticProps = {
  title: 'Stay in the loop',
  loggedInTitle: 'You’re logged in.',
  subtitle:
    'Be the first to know about new class launches, promotions and more!',
  loggedInSubtitle:
    'For updates on new classes click below to to subscribe to our weekly newsletter',
  btnText: 'Subscribe'
};

export const communityCardsSectionStaticProps = {
  title: 'Find your people today',
  cards: [
    {
      isWaitlist: true,
      discoveryCardImgData: {
        alt: 'Crypto Community',
        mobileImgProps: {
          height: 175,
          width: 312,
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/communities-page/png/crypto-community-card-background.png'
        }
      },
      avatarImgData: {
        alt: 'avatar Image',
        mobileImgProps: {
          height: 56,
          width: 56,
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/communities-page/png/nasacademy-avatar.png'
        },
        desktopImgProps: {
          height: 56,
          width: 56,
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/communities-page/png/nasacademy-avatar.png'
        }
      },
      by: 'Nas Academy',
      title: 'Crypto Community',
      description:
        'Meet people in web3, discover the latest NFT projects, and get involved in the movement that changed the internet',
      link: '/crypto-community',
      price: '$29'
    },
    {
      isWaitlist: false,
      ctaLabel: 'Apply Now', // TODO - Apply Now <- Change to Apply Now on release
      discoveryCardImgData: {
        alt: 'Content Creation Pro Community',
        mobileImgProps: {
          height: 175,
          width: 312,
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/community-product-page/nas-content-community/community-members/nasdaily-creators-discoverThumbnail.jpg'
          // src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/communities-page/png/content-creation-community-card-background.png'
        }
      },
      avatarImgData: {
        alt: 'avatar Image',
        mobileImgProps: {
          height: 56,
          width: 56,
          src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/community-product-page/nas-content-community/nuseir.png'
          // src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/communities-page/png/nasacademy-avatar.png'
        }
        // desktopImgProps: {
        //   height: 56,
        //   width: 56,
        //   src: 'https://d2oi1rqwb0pj00.cloudfront.net/na-website/communities-page/png/nasacademy-avatar.png'
        // }
      },
      by: 'Nuseir Yassin',
      title: 'Nas Daily Creator Community',
      description:
        'A community built by a Creator, for Creators. Get access to exclusive content and a global network.',
      link: '/creators'
      // price: '$9'
    }
  ]
};
