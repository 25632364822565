import NextImage from '@/components/common/NextImage';
import React from 'react';

const ImageBanner = ({
  imageProps,
  title,
  icon,
  rounded,
  isGtEqMd,
  ...rest
}) => {
  if (!imageProps.mobileImgProps.src) return null;

  let roundStyle = '';
  let imgRoundStyle = '';
  if (rounded) {
    if (isGtEqMd) {
      roundStyle = 'rounded-28';
      imgRoundStyle = 'rounded-b-28';
    } else {
      roundStyle = title ? '' : 'rounded-t-28';
      if (!title) {
        imgRoundStyle = 'rounded-t-28';
      }
    }
  }

  return (
    <div className={`overflow-hidden ${roundStyle} md:mb-24`} {...rest}>
      {title && (
        <div className="py-12 px-24 bg-dark-50 flex items-center">
          {icon && <span className="mr-8">{icon}</span>}
          <p className="text-label-md text-neutral-99 font-medium">
            {title}
          </p>
        </div>
      )}
      <NextImage {...imageProps} className={imgRoundStyle} />
    </div>
  );
};

export default ImageBanner;
