import LoginForm from '@/components/common/LoginModal/partials/LoginForm';
import useStudentLogin from '@/hooks/useStudentLogin';

const LoginModalV2 = ({
  postLoginCallback,
  isDisabledCta,
  defaultEmail,
  isLoading,
  isResetPassword
}) => {
  const {
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,
    loginInputErrors
  } = useStudentLogin();

  return (
    <div>
      <LoginForm
        onSubmit={onLoginSubmit}
        onChange={onLoginChange}
        formRef={formRef}
        error={loginError}
        isLoading={loginLoading || isLoading}
        loginInputErrors={loginInputErrors}
        isSignUpForm={false}
        showPhoneField={false}
        isDisabledCta={isDisabledCta}
        defaultEmail={defaultEmail}
        postLoginCallback={postLoginCallback}
        isResetPassword={isResetPassword}
      />
    </div>
  );
};

export default LoginModalV2;
