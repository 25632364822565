import HeadlessDropdown from '@/components/common/Form/HeadlessDropdown/HeadlessDropdown';
import Modal from '@/components/common/Modal';
import RoundedButton from '@/components/common/RoundedButton';
import {
  buildCalendarOnClick,
  buildCalendarURL
} from '@/pages/portal/utils/calendar';

export const EventConfirmationModal = (props) => {
  const {
    showModal,
    closeModal,
    title,
    eventTitle,
    communityTitle,
    primaryCta,
    secondaryCta,
    calendarDetails
  } = props;

  const description = (
    <span>
      You have successfully registered for event{' '}
      <span className="font-semibold">{eventTitle}</span>, and you have
      also become a part of{' '}
      <span className="font-semibold">{communityTitle}</span> community!
      Add the event to your calendar, and visit your community portal
      below.
    </span>
  );

  return (
    <Modal
      open={showModal}
      onClose={closeModal}
      customContainerClass="!max-w-[500px]"
      customInnerClass={'!px-24 !py-32'}>
      <div className="flex flex-col w-full">
        <div className="text-npl-text-icon-on-light-surface-primary text-heading-md font-semibold mb-8">
          {title}
        </div>
        <div className="text-npl-text-icon-on-light-surface-secondary text-body-md font-normal mb-24">
          {description}
        </div>
        <div className="flex justify-end items-center flex-row w-full gap-12">
          <HeadlessDropdown
            placement="top-start"
            renderField={() => (
              <RoundedButton
                displayType={primaryCta?.displayType}
                customClassNames="w-full flex justify-center">
                <div className="flex items-center">
                  <span className="!text-neutral-10 className='font-medium'">
                    {primaryCta?.text ?? 'Add to calendar'}
                  </span>
                </div>
              </RoundedButton>
            )}
            renderOptions={({ closeDropdown }) => (
              <div
                className="bg-white-default w-[150px] py-8 px-12 rounded-8 flex flex-col gap-4 text-neutral-10 text-14"
                style={{
                  boxShadow: `0 3px 28px rgb(0 0 0 / 8%`
                }}>
                <a
                  href={buildCalendarURL(calendarDetails, 'apple')}
                  onClick={(e) => {
                    buildCalendarOnClick(e);
                    closeDropdown();
                  }}
                  className="hover:text-primary py-2">
                  Apple Calendar
                </a>
                <a
                  href={buildCalendarURL(calendarDetails, 'google')}
                  onClick={(e) => {
                    buildCalendarOnClick(e);
                    closeDropdown();
                  }}
                  className="hover:text-primary py-2">
                  Google
                </a>
                <a
                  href={buildCalendarURL(calendarDetails, 'outlook')}
                  onClick={(e) => {
                    buildCalendarOnClick(e);
                    closeDropdown();
                  }}
                  className="hover:text-primary py-2">
                  Outlook
                </a>
                <a
                  href={buildCalendarURL(calendarDetails, 'outlookcom')}
                  onClick={(e) => {
                    buildCalendarOnClick(e);
                    closeDropdown();
                  }}
                  className="hover:text-primary py-2">
                  Outlook.com
                </a>
                <a
                  href={buildCalendarURL(calendarDetails, 'yahoo')}
                  onClick={(e) => {
                    buildCalendarOnClick(e);
                    closeDropdown();
                  }}
                  className="hover:text-primary py-2">
                  Yahoo
                </a>
              </div>
            )}
          />
          <RoundedButton
            displayType="primary"
            onClick={secondaryCta?.onClick}>
            {<span className="font-medium">{secondaryCta?.text}</span>}
          </RoundedButton>
        </div>
      </div>
    </Modal>
  );
};
