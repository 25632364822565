import classNames from 'classnames';
import React from 'react';

import style from './CheckmarkIcon.module.scss';

const CheckmarkIcon = ({ fillPrimary }) => {
  const className = 'c-CheckmarkIcon';
  const baseClassNames = classNames(className, style[className], {
    [style[`${className}--fill-primary`]]: fillPrimary
  });

  return (
    <svg
      className={baseClassNames}
      width="32"
      height="32"
      viewBox="0 0 36 36"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 18C6 11.376 11.376 6 18 6C24.624 6 30 11.376 30 18C30 24.624 24.624 30 18 30C11.376 30 6 24.624 6 18ZM10.44 18.84L14.748 23.148C15.216 23.616 15.984 23.616 16.44 23.148L25.548 14.04C26.016 13.572 26.016 12.816 25.548 12.348C25.08 11.88 24.324 11.88 23.856 12.348L15.6 20.604L12.132 17.148C11.664 16.68 10.908 16.68 10.44 17.148C10.2153 17.3722 10.089 17.6766 10.089 17.994C10.089 18.3114 10.2153 18.6158 10.44 18.84Z"
      />
      <ellipse
        cx="18"
        cy="17.8356"
        rx="16"
        ry="15.8356"
        fillOpacity="0.16"
      />
    </svg>
  );
};

export default CheckmarkIcon;
