import { countryList } from '@/data/CoursePage/wisenetMapCountryList';

function getFlagEmoji(countryCode) {
  if (countryCode) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }
  return '';
}

export const dialCodeOptions = [...countryList]
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(({ id, dialCode, flag, name, countryCode }) => ({
    value: id,
    label: `${dialCode} ${flag ? flag : getFlagEmoji(countryCode)}`,
    optionsDisplay: `${name} (${dialCode})`
  }));
