import classnames from 'classnames';
import NextImage from '@/components/common/NextImage';
import { ReadMore } from '@/components/common/ReadMore/ReadMore';
import React, { useCallback, useRef, useState } from 'react';
import ShareModal from '@/components/common/ShareModal/ShareModal';
import { resolveEventSlugUrl } from '@/pages/portal/utils/events';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { CARD_SHADOW } from '@/features/EventPublicPage/constants';
import LinkAwareText from '@/components/common/LinkAwareText';
import EventProminentUrl from '@/components/features/EventProminentUrl';

const EventDetail = ({
  event,
  community,
  className,
  isMobileView,
  prominentUrlProps
}) => {
  const { isGtEqMd } = useWindowWidthContext();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const rootRef = useRef();
  const shareUrl = resolveEventSlugUrl(community.link, event.slug);
  const scrollToNextComponent = useCallback(() => {
    if (rootRef.current) {
      const nextComponent = rootRef.current.nextElementSibling;
      if (nextComponent) {
        nextComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [rootRef]);
  return (
    <div
      ref={rootRef}
      className={classnames(
        `py-40 px-24 rounded-28 bg-white-default font-poppins tracking-[-0.017em]`,
        className
      )}
      style={{
        boxShadow: CARD_SHADOW
      }}>
      <button
        className="flex items-center w-full gap-24 text-left"
        onClick={scrollToNextComponent}>
        <div className="flex-grow flex-shrink-1">
          <h3 className="font-semibold text-neutral-10 text-20 leading-[26px]">
            Hosted on{' '}
            <div className="underline" data-testid="event-community-name">
              {community.title}
            </div>
          </h3>
          <h4 className="mt-8 font-medium text-16 text-neutral-50 leading-20">
            With {event.host.firstName} {event.host.lastName}
          </h4>
        </div>
        {event.host.profileImage && (
          <div className="w-56 h-56 rounded-full">
            <NextImage
              className="rounded-full"
              mobileImgProps={{
                src: event.host.profileImage,
                width: 56,
                height: 56
              }}
            />
          </div>
        )}
      </button>
      <div className="h-[1px] bg-neutral-90 my-36"></div>
      {prominentUrlProps && prominentUrlProps?.text && (
        <>
          <div>
            <h5 className="text-20 leading-26 font-semibold tracking-[-0.017em] text-neutral-10 mb-24">
              Where
            </h5>
            <div>
              <EventProminentUrl {...prominentUrlProps} />
            </div>
          </div>
          <div className="h-[1px] bg-neutral-90 my-36"></div>
        </>
      )}
      {event.description && (
        <>
          <h5 className="text-20 leading-26 font-semibold tracking-[-0.017em] text-neutral-10 mb-24">
            Event details
          </h5>
          {isGtEqMd ? (
            <div
              className="font-normal break-words whitespace-pre-line text-16 text-neutral-10"
              data-testid="event-details">
              <LinkAwareText text={event.description} />
            </div>
          ) : (
            <ReadMore
              withShowLess={true}
              maxHeight={120}
              className="font-normal break-words whitespace-pre-line text-16 text-neutral-10">
              <LinkAwareText text={event.description} />
            </ReadMore>
          )}
          <div className="h-[1px] bg-neutral-90 my-36"></div>
        </>
      )}
      {event.attendees >= 5 && (
        <div
          className={classnames(
            'font-semibold text-20 tracking-[-0.017em] text-neutral-10',
            isGtEqMd ? 'mb-8' : 'mb-24'
          )}>
          {event.attendees} people attending
        </div>
      )}
      <div
        className={classnames(
          `flex `,
          isGtEqMd
            ? 'flex-row items-center'
            : 'flex-col items-start gap-24'
        )}>
        {event.attendees >= 5 && (
          <>
            <div className="flex flex-row-reverse">
              {event.profileImages.map((url, index) => (
                <div className="-mr-8" key={url}>
                  <img
                    key={url}
                    src={url}
                    alt={`Attendees ${index}`}
                    className="box-border w-40 h-40 border-2 rounded-full border-white-default"
                  />
                </div>
              ))}
            </div>
          </>
        )}
        <button
          onClick={() => setShareModalOpen(true)}
          className={classnames(
            `text-14 font-medium text-neutral-10 px-24 py-12 rounded-full border-1 border-neutral-70`,
            isGtEqMd ? 'ml-auto' : ''
          )}>
          Spread the word
        </button>
        <ShareModal
          shareUrl={shareUrl}
          title="Share your event"
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          label="Event public page link"
          shareText={event.title}
          isMobileView={isMobileView}
        />
      </div>
    </div>
  );
};

export default EventDetail;
