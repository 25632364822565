import {
  signUpCheck,
  submitFreeCommunityEnrollment
} from '@/services/communitiesService';
import {
  getTrackingInformationForBE,
  trackBeginCheckout,
  trackGAEvent,
  trackInitiateCheckout
} from '@/utility/analytics';
import {
  SIGNUP_ORIGIN,
  TRACKING_COMMUNITY_TYPE,
  WHATSAPP_FORM_STATE
} from '../../constants';

import RoundedButton from '@/components/common/RoundedButton';
import { getLowestIntervalPricing } from '@/features/community/utils';
import useStartCheckout from '@/hooks/checkout/useStartCheckout';
import Icon from '@/icons/Icon';
import { communitiesSignUpService } from '@/services/communitiesSignUpService';
import { SIGNUP_SUCCESS } from '@/utility/analyticsConsts';
import { SIGN_UP_ACCESS_TOKEN_SESSION_KEY } from '@/utility/checkoutConstants';
import { getTimezoneId } from '@/utility/dateHelper';
import { validateEmail } from '@/utility/formHelpers';
import { useState } from 'react';
import { WHATSAPP_SIGNUP_REQUESTOR } from '../../constants';
import { showToast } from '@/components/common/ToastContainer';
import FloatingLabelInput from '@/components/common/Form/FloatingLabelInput';
import WhatsappUserLoginModal from '../WhatsappUserLoginModal';
import { EVENT_ACTION_TYPE } from '@/features/EventPublicPage/EventPublicPage';

const EmailForm = ({
  setFormState,
  signUpFormValues,
  setSignUpFormValues,
  signUpOriginProps
}) => {
  const { inputPhoneNumber, communityInfo, discountCodeFromQueryParams } =
    signUpFormValues;
  const {
    code,
    request_approval,
    isPaidCommunity,
    isFreeCommunity,
    link,
    isTokenGated,
    title,
    pricingData,
    _id: communityId
  } = communityInfo;
  const { eventInfo, resourceInfo, signUpOrigin } = signUpOriginProps;

  const isCheckoutFlowRequired = request_approval || isPaidCommunity;
  const checkoutTrackingObject = {
    isWhatsappCommunity: true,
    type: isTokenGated
      ? TRACKING_COMMUNITY_TYPE.NFT
      : isFreeCommunity
      ? TRACKING_COMMUNITY_TYPE.FREE
      : TRACKING_COMMUNITY_TYPE.PAID,
    community_name: title,
    community_id: communityId
  };

  const [emailValue, setEmailValue] = useState('');
  const [fullNameValue, setFullName] = useState('');
  const [isEmailUsed, setIsEmailUsed] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onCommunityCheckoutClick } = useStartCheckout({
    communityCode: code,
    slug: link,
    isCommunity: true,
    isFreeCommunity: isFreeCommunity,
    shouldPreventCheckoutRedirect: !isCheckoutFlowRequired,
    requestor: WHATSAPP_SIGNUP_REQUESTOR,
    actionType: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return EVENT_ACTION_TYPE;

      return;
    })(),
    sourceInfo: (() => {
      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        return {
          type: 'event',
          origin: eventInfo?._id
        };

      if (signUpOrigin === SIGNUP_ORIGIN.RESOURCE_PAGE)
        return {
          type: 'folder',
          origin: resourceInfo?._id
        };

      return;
    })(),
    discountCodeFromQueryParams
  });

  const onEmailChange = (e) => {
    setEmailValue(e.target.value);
    setEmailError('');
    setIsEmailUsed(false);
  };

  const onFullNameChange = (e) => {
    setFullName(e.target.value);
    setFullNameError('');
  };

  const onSubmit = async () => {
    try {
      const trimmedEmailValue = emailValue.trim();
      const trimmedFullNameValue = fullNameValue?.trim() ?? '';

      if (!trimmedFullNameValue) {
        setFullNameError('Full name is required');
        return;
      }

      if (!validateEmail(trimmedEmailValue)) {
        setEmailError('Email is invalid');
        return;
      }

      setIsSubmitting(true);

      const { data } = await signUpCheck(trimmedEmailValue);
      const { userExists, isActiveUser } = data;
      if (userExists) {
        setSignUpFormValues((prev) => ({
          ...prev,
          userEmail: trimmedEmailValue,
          isActiveUser
        }));
        setIsEmailUsed(userExists);
        setIsSubmitting(false);
      } else {
        const selectedPricingOption =
          getLowestIntervalPricing(pricingData);
        if (isCheckoutFlowRequired) {
          //we're redirecting to the checkout flow but its redirection is slow, so we don't remove the loading spinner
          const data = await onCommunityCheckoutClick({
            communityCode: code,
            interval:
              selectedPricingOption?.recurring?.interval ?? 'month',
            intervalCount:
              selectedPricingOption?.recurring?.interval_count ?? 1,
            inputEmail: trimmedEmailValue,
            inputPhoneNumber: inputPhoneNumber,
            inputFullName: trimmedFullNameValue,
            priceId: selectedPricingOption?.id
          });

          if (data.error) {
            showToast({ text: data.error, type: 'error' });
            throw new Error(data.error);
          }
        } else {
          trackBeginCheckout(checkoutTrackingObject);
          trackInitiateCheckout(checkoutTrackingObject);

          //if no users are using the phone number and email, user can enroll this community
          const payload = {
            timezone: getTimezoneId(),
            communityCode: code,
            phoneNumber: inputPhoneNumber,
            email: trimmedEmailValue,
            fullName: trimmedFullNameValue,
            captchaToken: null,
            isDirectSignUpEmail: false,
            interval:
              selectedPricingOption?.recurring?.interval ?? 'month',
            intervalCount:
              selectedPricingOption?.recurring?.interval_count ?? 1,
            priceId: selectedPricingOption?.id,
            trackingData: getTrackingInformationForBE(),
            requestor: WHATSAPP_SIGNUP_REQUESTOR
          };
          const {
            access_token: bookingToken,
            id: bookingId,
            error
          } = await communitiesSignUpService(payload);

          if (error) {
            showToast({ text: error.info, type: 'error' });
            throw new Error(error.info);
          }

          sessionStorage.setItem(
            SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
            bookingToken
          );
          setSignUpFormValues((prev) => ({
            ...prev,
            bookingId
          }));
          if (bookingToken) {
            await submitFreeCommunityEnrollment();
          }
          setFormState(WHATSAPP_FORM_STATE.SUCCESS);
          trackGAEvent(SIGNUP_SUCCESS, checkoutTrackingObject);
        }
      }
    } catch (e) {
      setIsSubmitting(false);
      throw new Error(e.message);
    }
  };

  return (
    <div className="w-full max-w-[420px] bg-white-default rounded-28 shadow-npl-styles-shadow-01 p-24 my-24 flex items-center flex-col relative">
      <div className="mb-24 space-y-8 text-center">
        <p className="font-semibold text-heading-xs text-npl-text-icon-on-light-surface-primary">
          Nice, almost there!
        </p>
      </div>
      <form
        className="w-full mt-16"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}>
        <div className="w-full mb-8">
          <FloatingLabelInput
            type="text"
            name="fullName"
            placeholder="Full name"
            label="Full name"
            floatingLabel="Full name"
            onChange={onFullNameChange}
            error={fullNameError}
          />
        </div>
        <div className="w-full mb-16">
          <FloatingLabelInput
            type="email"
            name="email"
            placeholder="Email address"
            label="Email address"
            floatingLabel="Email address"
            onChange={onEmailChange}
            error={emailError}
          />
        </div>

        {isEmailUsed && (
          <WhatsappUserLoginModal
            formState={WHATSAPP_FORM_STATE.EMAIL}
            signUpFormValues={signUpFormValues}
            setSignUpFormValues={setSignUpFormValues}
            setFormState={setFormState}
            onClose={() => setIsEmailUsed(false)}
            showMessage={isEmailUsed}
            message="Email already in use, please insert another or try logging in."
            signUpOriginProps={signUpOriginProps}
          />
        )}

        <RoundedButton
          type="submit"
          displayType="primary"
          customClassNames="w-full flex justify-center"
          onClick={onSubmit}
          isLoading={isSubmitting}
          disabled={isEmailUsed}>
          Continue
        </RoundedButton>
      </form>
      <div
        tabIndex={0}
        role="button"
        className="absolute left-24 top-24"
        onClick={() => setFormState(WHATSAPP_FORM_STATE.PHONE)}>
        <Icon
          name="arrow-left"
          width={24}
          height={24}
          fill="rgba(27, 27, 24, 0.5)"
        />
      </div>
    </div>
  );
};

export default EmailForm;
