import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import AlertIcon from '../Icons/AlertIcon';
import {
  EXISTING_ENROLLMENT_MODAL,
  NEW_ENROLLMENT_MODAL,
  responseModalSuccessProps,
  WAITLIST_MODAL_ERROR_MESSAGE
} from '@/pages/communities/constants';

const TRY_AGAIN_TEXT = 'Try again';
const CLOSE_TEXT = 'Close';

const WaitlistResponseModalContent = ({
  apiError,
  alreadySubmittedError,
  dismiss
}) => {
  const renderTitle = useCallback(
    (type) => <>{responseModalSuccessProps[type].title} </>,
    []
  );

  const renderDescription = useCallback(
    () =>
      !apiError && (
        <p className="text-12 xl:text-14 text-dark-66">
          {alreadySubmittedError
            ? responseModalSuccessProps?.[EXISTING_ENROLLMENT_MODAL]
                ?.description
            : responseModalSuccessProps?.[NEW_ENROLLMENT_MODAL]
                ?.description}
        </p>
      ),
    [alreadySubmittedError, apiError]
  );

  const renderErrorMessage = useCallback(
    () => (
      <p className="text-center xl:text-24">
        {WAITLIST_MODAL_ERROR_MESSAGE[0]}
        <br /> {WAITLIST_MODAL_ERROR_MESSAGE[1]}
      </p>
    ),
    []
  );

  return (
    <div className="font-poppins">
      {apiError && (
        <div className="flex justify-center">
          <AlertIcon />
        </div>
      )}
      <h2 className="text-dark-33 font-semibold mb-8 text-20 xl:text-24">
        {apiError
          ? renderErrorMessage()
          : alreadySubmittedError
          ? renderTitle(EXISTING_ENROLLMENT_MODAL)
          : renderTitle(NEW_ENROLLMENT_MODAL)}
      </h2>
      {renderDescription()}
      {apiError && (
        <div className="flex justify-center mt-24 xl:mt-32">
          <Button displayType="primary" onClick={dismiss}>
            {apiError ? TRY_AGAIN_TEXT : CLOSE_TEXT}
          </Button>
        </div>
      )}
    </div>
  );
};

WaitlistResponseModalContent.propTypes = {
  apiError: PropTypes.bool,
  alreadySubmittedError: PropTypes.bool,
  dismiss: PropTypes.func
};

export default WaitlistResponseModalContent;
