import ClampText from '@/components/common/ClampText';
import { showToast } from '@/components/common/ToastContainer';
import ToolTip from '@/components/common/ToolTip';
import Icon from '@/icons/index';
import {
  EVENT_TYPE_IN_PERSON,
  EVENT_TYPE_LIVE
} from '@/pages/portal/events/constants';
import { copyToClipboard } from '@/utility/helpers';
import React from 'react';
import * as yup from 'yup';

const checkValidUrl = ({ url }) => {
  if (!url) return false;

  let schema = yup.string().url();
  return schema.isValidSync(url);
};

const EventProminentUrl = ({ text = '', type, showCopyIcon = false }) => {
  const icon = (() => {
    if (type === EVENT_TYPE_LIVE) return <Icon name="globe-02" />;
    if (type === EVENT_TYPE_IN_PERSON)
      return <Icon name="marker-pin-01" />;

    return;
  })();

  const copyText = () => {
    copyToClipboard(text);

    const copiedText = (() => {
      if (type === EVENT_TYPE_LIVE) return 'Link Copied!';
      if (type === EVENT_TYPE_IN_PERSON) return 'Address Copied!';

      return 'Copied!';
    })();
    showToast({ text: copiedText });
  };

  const renderLocationText = () => {
    const isValidUrl = checkValidUrl({ url: text });

    if (isValidUrl)
      return (
        <a
          href={text}
          className="flex items-center space-x-8"
          target="_blank"
          rel="noreferrer noopener">
          {icon && <div className="flex-shrink-0">{icon}</div>}
          <p className="underline">
            <ClampText line={1} hideCTA={true} text={text} />
          </p>
        </a>
      );

    return (
      <div className="flex items-center space-x-8">
        {icon && <div className="flex-shrink-0">{icon}</div>}
        <p>
          <ClampText line={1} hideCTA={true} text={text} />
        </p>
      </div>
    );
  };

  return (
    <div className="w-full flex items-center justify-between space-x-20 text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
      {renderLocationText()}
      {showCopyIcon && (
        <div>
          <ToolTip text="Copy">
            <div
              role="button"
              tabIndex={0}
              className="flex-shrink-0"
              onClick={copyText}>
              <Icon name="copy-02" />
            </div>
          </ToolTip>
        </div>
      )}
    </div>
  );
};

export default EventProminentUrl;
