import {
  DROPDOWN_FIELD_TYPE,
  INPUT_FIELD_TYPE,
  dialCodeOptions,
  getCountryIdByName,
  getDialCodeById,
  dialCodeOptionsWithoutFlag
} from '@/utility/formHelpers';
import { useEffect, useMemo, useState } from 'react';

import FormField from '../Form/FormField';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getUserCountryData } from '@/services/paymentService';

const className = 'c-PhoneNumberInput';
const PhoneNumberInput = ({
  phoneNumber,
  phoneNumberPlaceHolder,
  setPhoneNumber,
  error,
  label,
  showFlag,
  customClassNames,
  isFloating = false,
  floatingLabel,
  onTextFocus,
  onTextBlur
}) => {
  const [countryId, setCountryId] = useState(null);
  const [phone, setPhone] = useState('');
  const FIELD_CONFIG = {
    dialCodeId: {
      fieldType: DROPDOWN_FIELD_TYPE,
      name: 'dialCodeId',
      placeholder: 'Dial Code',
      optionsList: showFlag ? dialCodeOptions : dialCodeOptionsWithoutFlag,
      style: {
        width: '105px',
        border: '1px solid #C5C6C9',
        borderRadius: '12px',
        marginTop: '8px'
      },
      required: true
    },
    phoneNumber: {
      fieldType: INPUT_FIELD_TYPE,
      name: 'phoneNumber',
      placeholder: phoneNumberPlaceHolder,
      optional: true
    }
  };

  const setCountryIdByIp = async () => {
    const { data, error } = await getUserCountryData();
    if (error) {
      return;
    }
    const country = getCountryIdByName(data.country);
    if (country?.id) setCountryId(country?.id);
  };

  const onFieldChange = (name, value) => {
    if (name === 'dialCodeId') {
      setCountryId(value);
    } else {
      setPhone(value);
    }
  };

  const phNumberWithDialCode = useMemo(
    () => `${getDialCodeById(countryId)}${phone}`,
    [countryId, phone]
  );

  const renderFormField = (field) => {
    const {
      name,
      fieldType,
      placeholder,
      optionsList,
      value = ''
    } = field;
    const error = error?.[name] || '';
    return (
      <FormField
        fieldType={fieldType}
        name={name}
        onChange={(e) => onFieldChange(e.target.name, e.target.value)}
        value={value}
        error={error}
        placeholder={placeholder}
        optionsList={optionsList}
        {...field}
      />
    );
  };

  useEffect(() => {
    if (!phoneNumber) {
      setCountryIdByIp();
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (phoneNumber !== phNumberWithDialCode) {
      setPhoneNumber && setPhoneNumber(phNumberWithDialCode);
    }
  }, [phNumberWithDialCode, phoneNumber, setPhoneNumber]);

  return (
    <div
      className={classNames(className, 'flex flex-col', customClassNames)}>
      <label
        htmlFor={FIELD_CONFIG.phoneNumber.name}
        className="font-medium text-label-lg text-npl-text-icon-on-light-surface-primary">
        {label}
      </label>
      <div className="flex flex-row mt-8">
        <div className="flex-grow-0">
          {renderFormField({
            ...FIELD_CONFIG.dialCodeId,
            value: countryId,
            className:
              '!bg-npl-base-white !rounded-12 !border !border-solid !border-light-e0'
          })}
        </div>
        <div className="flex-grow">
          {isFloating
            ? renderFormField({
                ...FIELD_CONFIG.phoneNumber,
                value: phone,
                ...(isFloating && {
                  type: 'number',
                  label: floatingLabel,
                  isFloating: true,
                  customClassInput: 'w-full h-full mt-8',
                  onFocus: onTextFocus,
                  onBlur: onTextBlur
                })
              })
            : renderFormField({
                ...FIELD_CONFIG.phoneNumber,
                value: phone,
                className:
                  '!bg-npl-base-white !rounded-12 !border !border-solid !border-light-e0'
              })}
        </div>
      </div>
      {error && <div className="text-button-sm text-error">{error}</div>}
    </div>
  );
};

PhoneNumberInput.propTypes = {
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string
};
PhoneNumberInput.defaultProps = {
  showFlag: true,
  phoneNumberPlaceHolder: 'Phone number'
};

export default PhoneNumberInput;
