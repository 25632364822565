import React, { useCallback, useMemo, useRef, useState } from 'react';

import config from '@/utility/config';
import { useAdminAuthContext } from '@/contexts/AdminAuthContext';
import { useAuthContext } from '@/contexts/AuthContext';
import { useRouter } from 'next/router';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import NasIoLogoDark from '@/components/common/IconsV2/NasIoLogoDark';
import RoundedButton from '@/components/common/RoundedButton';
import EditPencil from '@/components/common/IconsV2/EditPencil';
import useOutsideComponentClickDetect from '@/hooks/useOutsideComponentClickDetect';

const className = 'c-Navbar';

const Navbar = ({
  sticky,
  loading,
  isCommunityAdmin,
  isCommunityMember,
  navigateToLearnPortal,
  navigateToAdminPortal,
  handleAdminEdit,
  isDemoCommunity
}) => {
  const { isGtEqLg } = useWindowWidthContext();
  const { isLoggedIn: isUserLoggedIn, user } = useAuthContext();
  const { isAdmin } = useAdminAuthContext();
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const ref = useRef(null);

  useOutsideComponentClickDetect(ref, () => setShowAdminMenu(false));

  const adminMenuOptions = [
    { value: 'admin', label: 'Manager Portal' },
    { value: 'member', label: 'Member Portal' }
  ];

  const router = useRouter();

  const onLoginHandler = () => {
    router.push('/login');
  };

  const onUserClickHandler = () => {
    if (isCommunityMember) return navigateToLearnPortal();
    else return router.push(config.memberPortalPath);
  };

  const onAdminClickHandler = () => {
    if (isGtEqLg) {
      return setShowAdminMenu(!showAdminMenu);
    } else {
      return navigateToLearnPortal();
    }
  };

  const navigateAdminClick = (location) => {
    switch (location) {
      case 'admin':
        return navigateToAdminPortal();
      case 'member':
        return navigateToLearnPortal();
      default:
        return null;
    }
  };

  const getDisabledStateForButton = () => {
    if (isDemoCommunity) {
      return true;
    }
    return false;
  };

  const renderMenu = () => {
    return (
      <div
        ref={ref}
        className="absolute bg-npl-base-white p-8 rounded-12 top-[44px] right-0 shadow-lg w-[200px] z-[101]">
        <div className="flex flex-col">
          {adminMenuOptions.map((option, key) => (
            <div
              onClick={() => navigateAdminClick(option.value)}
              tabIndex={0}
              role="button"
              key={key}
              className="w-full px-8 py-12 cursor-pointer rounded-8 text-label-md text-npl-text-icon-on-light-surface-primary hover:bg-npl-neutral-light-7">
              {option?.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderLoginCta = () => (
    <RoundedButton
      onClick={onLoginHandler}
      customClassNames="!text-npl-text-icon-on-light-surface-primary bg-npl-base-white text-body-sm border-1 hover:bg-npl-neutral-light-7 !border-npl-neutral-light-7"
      displayType={'outline'}
      loading={loading}
      size="md">
      <div className="flex flex-row items-center justify-center w-full gap-8">
        Log in
      </div>
    </RoundedButton>
  );

  const renderUserCta = () => (
    <RoundedButton
      onClick={onUserClickHandler}
      customClassNames="bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary hover:bg-npl-neutral-dark-9"
      displayType={'custom'}
      loading={loading}
      size="md">
      <div className="flex flex-row items-center justify-center w-full gap-8">
        Go to Portal
      </div>
    </RoundedButton>
  );

  const renderAdminCtas = () => (
    <div className="relative flex flex-row items-center gap-12">
      {isCommunityAdmin && isGtEqLg && (
        <RoundedButton
          disabled={getDisabledStateForButton()}
          onClick={handleAdminEdit}
          customClassNames="text-npl-text-icon-on-light-surface-primary  bg-npl-base-white text-body-sm border-1 hover:bg-npl-neutral-light-7 border-npl-neutral-light-7"
          displayType={'custom'}
          loading={loading}
          size="md">
          <div className="flex flex-row items-center justify-center w-full gap-8">
            <EditPencil
              fill="#1b1b18"
              customClassNames={'mb-4 !h-18 !w-18'}
            />
            Edit
          </div>
        </RoundedButton>
      )}
      <RoundedButton
        disabled={getDisabledStateForButton()}
        onClick={onAdminClickHandler}
        customClassNames="bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary hover:bg-npl-neutral-dark-9"
        displayType={'custom'}
        loading={loading}
        size="md">
        <div className="flex flex-row items-center justify-center w-full gap-8">
          Go to Portal
        </div>
      </RoundedButton>
      {showAdminMenu && renderMenu()}
    </div>
  );

  const renderCTAs = useMemo(() => {
    if (!user) return renderLoginCta();

    if (isAdmin) return renderAdminCtas();

    if (user) return renderUserCta();
  }, [
    isCommunityAdmin,
    isCommunityMember,
    isAdmin,
    isUserLoggedIn,
    showAdminMenu
  ]);

  const handleRedirectionForLogoClick = () => {
    return getDisabledStateForButton() ? '#' : '/';
  };
  return (
    <div
      className={`${className} ${
        sticky ? ' sticky' : ' block'
      } w-full h-64 flex flex-row justify-between items-center py-14 px-20 bg-npl-base-white`}>
      <a href={handleRedirectionForLogoClick()}>
        <NasIoLogoDark />
      </a>
      {renderCTAs}
    </div>
  );
};
export default Navbar;
