import NasioGrayscaleIcon from '@/components/common/IconsV2/NasioGrayscaleIcon';
import classnames from 'classnames';

const EventFooter = ({ className }) => {
  return (
    <div
      className={classnames(
        `text-neutral-50 flex flex-col items-center justify-center gap-8 text-12 font-medium`,
        className
      )}>
      <div>Built on</div>
      <a href="/">
        <NasioGrayscaleIcon />
      </a>
    </div>
  );
};

export default EventFooter;
