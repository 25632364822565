import { useCallback, useEffect, useState } from 'react';

import { openInNewTab } from '@/components/common/Editor/utils/openInNewTab';
import LoadingCircle from '@/components/common/LoadingCircle';
import Modal from '@/components/common/Modal';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { showToast } from '@/components/common/ToastContainer';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { getRedirectLinkOnPaymentSuccess } from '@/services/signUpService';
import { updateLearner } from '@/services/whatsappService';
import config from '@/utility/config';
import { SIGNUP_ORIGIN } from '../../constants';
import WarningPhoneNumberToast from './WarningPhoneNumberToast';
import { formatNumber } from '@/utility/helpers';

const SuccessForm = ({
  signUpFormValues,
  setSignUpFormValues,
  signUpOriginProps
}) => {
  const {
    whatsappInvitationLink,
    isWaPhoneNumberTaken,
    communityInfo,
    priceTagText,
    inputPhoneNumber,
    isCommunityMember,
    isLoggedIn,
    bookingId //if bookingId is not null, it means this case is a newly registered user account
  } = signUpFormValues;

  const {
    title,
    profileImage,
    request_approval,
    isFreeCommunity,
    totalMemberCount,
    _id: communityId,
    applicationConfig
  } = communityInfo;
  const {
    isPaid,
    signUpOrigin,
    onEventOriginSignUpSuccess,
    onResourceOriginSignUpSuccess,
    onEventSuccessRedirectClick
  } = signUpOriginProps;

  const showSignUpLoader =
    [SIGNUP_ORIGIN.EVENT_PAGE, SIGNUP_ORIGIN.RESOURCE_PAGE].includes(
      signUpOrigin
    ) && isPaid;

  const { isGtEqMd } = useWindowWidthContext();
  const [
    isWhatsappMobileRedirectModalOpen,
    setIsWhatsappMobileRedirectModalOpen
  ] = useState(false);

  const showWarningToast = useCallback(() => {
    showToast({
      autoCloseTime: 10000,
      text: <WarningPhoneNumberToast />,
      type: 'warning',
      nplVersion: 2
    });
  }, []);

  const fetchRedirectLink = async (isPhoneNumberUpdated) => {
    if (!whatsappInvitationLink && bookingId && isFreeCommunity) {
      const { data } = await getRedirectLinkOnPaymentSuccess({
        isCommunity: true,
        bookingId
      });
      const { whatsappInvitationLink, authToken } = data || {};
      setSignUpFormValues((prev) => ({
        ...prev,
        whatsappInvitationLink: whatsappInvitationLink
      }));

      if (isPhoneNumberUpdated) {
        //if the phone number is not taken, update learner's phone number accordingly
        await updateLearner(
          { phoneNumber: inputPhoneNumber.replace('+', '') },
          authToken
        );
      }

      // auto redirect to whatsapp invitation link in 3 seconds
      setTimeout(() => {
        openInNewTab(whatsappInvitationLink);
        setIsWhatsappMobileRedirectModalOpen(true);
      }, 3000);

      showToast({
        text: 'WhatsApp number saved',
        type: 'success',
        nplVersion: 2
      });
    }
  };

  useEffect(() => {
    if (isCommunityMember && isLoggedIn) {
      if (isWaPhoneNumberTaken) {
        //only show the warning toast once the phone number has been taken by another user
        showWarningToast();
      } else {
        //update the phone number for the logged-in member
        fetchRedirectLink(true);
      }
    } else {
      //if WhatsApp invitation link is not available in this FREE community, but we have booking id
      //we'll call redirect-link API to get the invitation link instead
      //this case is only applicable for newly registered users (non-logged-in)
      fetchRedirectLink();

      if (signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE)
        onEventOriginSignUpSuccess({ bookingId });

      if (signUpOrigin === SIGNUP_ORIGIN.RESOURCE_PAGE)
        onResourceOriginSignUpSuccess({ bookingId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaPhoneNumberTaken, isCommunityMember, isLoggedIn]);

  if (showSignUpLoader) return <LoadingCircle withFullScreen={true} />;

  return (
    <>
      <div className="w-full max-w-[420px] bg-white-default rounded-28 shadow-npl-styles-shadow-01 p-24 my-24 flex items-center flex-col">
        <div className="w-64 h-64">
          <NextImage
            alt="community-image"
            className="rounded-8"
            mobileImgProps={{
              src: profileImage,
              layout: 'responsive',
              objectFit: 'cover',
              width: 64,
              height: 64
            }}
          />
        </div>
        <div className="my-24 space-y-8 text-center">
          <p className="font-semibold text-heading-md text-npl-text-icon-on-light-surface-primary">
            {title}
          </p>
          <p className="font-medium text-label-lg text-npl-text-icon-on-light-surface-secondary">
            {`${formatNumber(totalMemberCount)} ${
              totalMemberCount === 1 ? 'member' : 'members'
            }`}
            {!isFreeCommunity && priceTagText && ' • '}
            {!isFreeCommunity && priceTagText ? priceTagText : ''}
          </p>
          {request_approval && !applicationConfig?.autoApproval && (
            <p className="text-label-md text-npl-text-icon-on-light-surface-tertiary">
              Approval required
            </p>
          )}
        </div>
        <div className="w-full mt-16 space-y-8">
          <RoundedButton
            link={whatsappInvitationLink}
            displayType="custom"
            customClassNames="w-full flex justify-center bg-[#25D366]">
            Open WhatsApp group
          </RoundedButton>
          {!bookingId && (
            <RoundedButton
              link={`${config.memberPortalPath}/communities/${communityId}/home`}
              displayType="outline"
              customClassNames="w-full flex justify-center">
              Go to community
            </RoundedButton>
          )}
          {signUpOrigin === SIGNUP_ORIGIN.EVENT_PAGE && (
            <RoundedButton
              onClick={onEventSuccessRedirectClick}
              displayType="outline"
              customClassNames="w-full flex justify-center">
              Go to community
            </RoundedButton>
          )}
        </div>
        {!isGtEqMd && (
          <Modal
            open={isWhatsappMobileRedirectModalOpen}
            showCloseIcon={false}
            customContainerClass="!rounded-12"
            customInnerClass="!px-24 !py-16">
            <div className="space-y-16">
              <p className="font-medium text-button-lg text-npl-text-icon-on-light-surface-primary">
                Open this page in &quot;WhatsApp&quot;?
              </p>
              <div className="flex justify-end space-x-24">
                <button
                  className="text-button-lg font-medium text-[#3578f6]"
                  onClick={() =>
                    setIsWhatsappMobileRedirectModalOpen(false)
                  }>
                  Cancel
                </button>
                <button
                  className="text-button-lg font-medium text-[#3578f6]"
                  onClick={() => openInNewTab(whatsappInvitationLink)}>
                  Open
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default SuccessForm;
