import React from 'react';
import RoundedButton from '@/components/common/RoundedButton';
import styles from './BottomSticky.module.scss';
import classnames from 'classnames';

const BottomSticky = ({ title, timing, primaryCta, visible }) => {
  if (primaryCta?.displayType !== 'primary') {
    return null;
  }
  return (
    <div
      className={classnames(
        `fixed bottom-0 left-0 w-full py-16 px-24 border-t border-t-neutral-90 flex items-center bg-white-default gap-32`,
        visible ? styles.visible : styles.hidden
      )}>
      <div className="flex-grow flex-shrink">
        <h3 className="font-semibold text-14 text-neutral-10">{title}</h3>
        <div className="text-12 text-neutral-50 font-medium">
          {`${timing.weekday.substring(0, 3)}, ${timing.day} ${
            timing.month
          } • ${timing.startTime}`}
        </div>
      </div>
      <div className="flex-grow-0 flex-shrink-0">
        <RoundedButton
          displayType={primaryCta.displayType}
          size="lg"
          customClassNames="w-full flex justify-center !text-14 !py-12 !px-24 !font-medium !h-auto"
          onClick={primaryCta.onClick}>
          {primaryCta.text}
        </RoundedButton>
      </div>
    </div>
  );
};

export default BottomSticky;
