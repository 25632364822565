export const WHATSAPP_FORM_STATE = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  SUCCESS: 'SUCCESS',
  ONE_CLICK: 'ONE_CLICK'
};

export const TRACKING_COMMUNITY_TYPE = {
  FREE: 'free',
  PAID: 'paid',
  NFT: 'nft'
};

export const WHATSAPP_SIGNUP_REQUESTOR = 'whatsappSignup';

export const SIGNUP_ORIGIN = {
  LANDING_PAGE: 'landing-page',
  EVENT_PAGE: 'event-page',
  RESOURCE_PAGE: 'resource-page'
};
